import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import CategoryProducts from '~/pages/Panel/Category/Details/Product/CategoryProducts';

import * as CategoryActions from '~/actions/category';
import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import { USER_PAGE_TYPE, PAGE_TYPE } from '~/enum/pageType';
import { CATEGORY_TYPE } from '~/enum/category';
import { getRouteStackPath } from '~/config/routes';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';

const formInitialValues: models.Category = {
  storeIdentifier: '',
  title: '',
  type: CATEGORY_TYPE.PRODUCT,
  products: [],
  tablesRestaurant: [],
};

const CategoryDetails: React.FC = (props) => {
  const dispatch = useDispatch();

  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);

  const { pathname } = useLocation();
  const { category, auth } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.Category) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (category && category.detail) {
      setForm(category.detail);
    } else {
      setForm(formInitialValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(CategoryActions.cleanCategoryDetail());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  useEffect(() => {
    if (
      auth.me?.profileType !== USER_PAGE_TYPE.SHOPKEEPER
      && auth.me?.profileType !== USER_PAGE_TYPE.RESTAURANT
    ) {
      window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    }

    onFormChange('storeIdentifier', auth.me?.storeIdentifier);
    onFormChange(
      'type', 
      auth.me?.profileType === USER_PAGE_TYPE.SHOPKEEPER 
        ? CATEGORY_TYPE.PRODUCT 
        : CATEGORY_TYPE.TABLE_RESTAURANT
    );
  }, [auth]);

  const onFormSubmit = () => {
    const requestForm: any = {
      storeIdentifier: form.storeIdentifier,
      title: form.title,
      type: form.type,
    };

    if (!form.title) {
      return MessageService.error('PAGES.PANEL.CATEGORY.DETAILS.FORM.ERROR.TITLE');
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(CategoryActions.updateCategory(category?.detail?.id as string, requestForm));
    } else {
      dispatch(CategoryActions.addCategory(requestForm));
    } 
  };

  return (
    <div className="category">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.CATEGORY.REPORT.TITLE'),
                url: getRouteStackPath('COMMERCIAL', 'CATEGORY_REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.CATEGORY.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="category__details__form">
            <div className="category__details__form__title">
              <h3 className="category__details__form__title__text">
                {translate('PAGES.PANEL.CATEGORY.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.CATEGORY.DETAILS.FORM.SUBJECT.LABEL')}
                    value={form.title}
                    onChange={(val: string | null) => onFormChange('title', val)}
                  />
                </Col>
              </Row>  
              <Row>
                <Col md={6}>
                  <Link to={getRouteStackPath('COMMERCIAL', 'CATEGORY_REPORT')}>
                    <AdvancedButton
                      variant="text"
                      text={translate(
                        'PAGES.PANEL.CATEGORY.DETAILS.FORM.BACK.LABEL'
                      )}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md={2}>
                  <AdvancedButton
                    type="submit"
                    className="push__advanced-button"
                    text={translate(
                      'PAGES.PANEL.CATEGORY.DETAILS.FORM.SUBMIT.LABEL'
                    )}
                    endIcon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>

      {pageType === PAGE_TYPE.EDIT
        && form.type === CATEGORY_TYPE.PRODUCT 
        && form.products?.length
        && form.products?.length > 0 
        && <CategoryProducts products={form.products} />
      }
    </div>
  );
};
export default CategoryDetails;
