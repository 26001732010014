import { Dispatch } from 'redux';
import * as MessageService from '~/services/message';
import ServiceRequests from '~/repositories/service';

import { SERVICE_DETAIL, SERVICE_REPORT, SERVICE_ALL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanServiceDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: SERVICE_DETAIL,
  });
};

export const getServiceAll = () => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Service> = await ServiceRequests.getAll();

    dispatch({
      payload: payload.rows,
      type: SERVICE_ALL,
    });

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const getServiceReport = (
  searchParams: advancedFilterModels.baseFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Service> = await ServiceRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page > 0 ? searchParams.page - 1 : searchParams.page,
        limit: searchParams.pageSize,
        isDESC: searchParams.sort === 'desc' ? 'true' : 'false',
      }
    );

    dispatch({
      payload: {
        ...payload,
        rows: payload.rows.map((o) => {
          return {
            ...o,
            id: o.identifier ? o.identifier : o.id,
          }
        }),
      },
      type: SERVICE_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getServiceDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Service = await ServiceRequests.getDetail(id);

    dispatch({
      payload,
      type: SERVICE_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};
