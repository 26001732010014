import getInstance from './instance';

const ReservationApi = {
  getReport: async (params: advancedFilterModels.ReservationAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/reservation', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/reservation/${id}`);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`/reservation/${id}`);

    return data;
  },

  get: async (apiUrl: string, params: advancedFilterModels.ReservationCalendarAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get(apiUrl, { params });

    return data;
  }
};

export default ReservationApi;
