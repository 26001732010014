export const INCREASE_LOADING = 'INCREASE_LOADING';
export const DESCREASE_LOADING = 'DESCREASE_LOADING';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ME = 'AUTH_ME';
export const AUTH_CHECK_LOGGED = 'AUTH_CHECK_LOGGED';

export const CATEGORY_REPORT = 'CATEGORY_REPORT';
export const CATEGORY_DETAIL = 'CATEGORY_DETAIL';
export const CATEGORY_ALL = 'CATEGORY_ALL';

export const PRODUCT_REPORT = 'PRODUCT_REPORT';
export const PRODUCT_DETAIL = 'PRODUCT_DETAIL';

export const PUSH_NOTIFICATION_REPORT = 'PUSH_NOTIFICATION_REPORT';
export const PUSH_NOTIFICATION_DETAIL = 'PUSH_NOTIFICATION_DETAIL';

export const STORE_REPORT = 'STORE_REPORT';
export const STORE_DETAIL = 'STORE_DETAIL';
export const STORE_ALL = 'STORE_ALL';

export const USER_REPORT = 'USER_REPORT';
export const USER_DETAIL = 'USER_DETAIL';

export const RESTAURANT_REPORT = 'RESTAURANT_REPORT';
export const RESTAURANT_DETAIL = 'RESTAURANT_DETAIL';
export const RESTAURANT_ALL = 'RESTAURANT_ALL';
export const RESTAURANT_GASTRONOMY_ALL = 'RESTAURANT_GASTRONOMY_ALL';
export const RESTAURANT_MEAL_ALL = 'RESTAURANT_MEAL_ALL';

export const TABLE_RESTAURANT_REPORT = 'TABLE_RESTAURANT_REPORT';
export const TABLE_RESTAURANT_DETAIL = 'TABLE_RESTAURANT_DETAIL';

export const RESERVATION_REPORT = 'RESERVATION_REPORT';
export const RESERVATION_DETAIL = 'RESERVATION_DETAIL';
export const RESERVATION_CALENDAR = 'RESERVATION_CALENDAR';

export const POSTER_REPORT = 'POSTER_REPORT';
export const POSTER_DETAIL = 'POSTER_DETAIL';
export const POSTER_COUNT = 'POSTER_COUNT';

export const EVENT_REPORT = 'EVENT_REPORT';
export const EVENT_DETAIL = 'EVENT_DETAIL';
export const EVENT_ALL = 'EVENT_ALL';

export const CAMPAIGN_PROMOTION_REPORT = 'CAMPAIGN_PROMOTION_REPORT';
export const CAMPAIGN_PROMOTION_DETAIL = 'CAMPAIGN_PROMOTION_DETAIL';
export const CAMPAIGN_PROMOTION_ALL = 'CAMPAIGN_PROMOTION_ALL';

export const SERVICE_REPORT = 'SERVICE_REPORT';
export const SERVICE_DETAIL = 'SERVICE_DETAIL';
export const SERVICE_ALL = 'SERVICE_ALL';

export const COUPON_REPORT = 'COUPON_REPORT';
export const COUPON_DETAIL = 'COUPON_DETAIL';

export const SOUND_ALERT_REPORT = 'SOUND_ALERT_REPORT';
export const SOUND_ALERT_DETAIL = 'SOUND_ALERT_DETAIL';
export const SOUND_ALERT_ALL = 'SOUND_ALERT_ALL';
export const SOUND_ALERT_COUNT = 'SOUND_ALERT_COUNT';