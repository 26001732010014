import { Dispatch } from 'redux';
import * as MessageService from '~/services/message';
import StoreRequests from '~/repositories/store';

import { STORE_DETAIL, STORE_REPORT, STORE_ALL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanStoreDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: STORE_DETAIL,
  });
};

export const getStoreAll = () => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Store> = await StoreRequests.getAll();

    dispatch({
      payload: payload.rows,
      type: STORE_ALL,
    });

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const getStoreReport = (
  searchParams: advancedFilterModels.StoreAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Store> = await StoreRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page > 0 ? searchParams.page - 1 : searchParams.page,
        limit: searchParams.pageSize,
        isDESC: searchParams.sort === 'desc' ? 'true' : 'false',
      }
    );

    dispatch({
      payload: {
        ...payload,
        rows: payload.rows.map((o) => {
          return {
            ...o,
            id: o.identifier ? o.identifier : o.id,
          }
        }),
      },
      type: STORE_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getStoreDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Store = await StoreRequests.getDetail(id);

    dispatch({
      payload,
      type: STORE_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};
