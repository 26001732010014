import getInstance from './instance';

const TableRestaurantApi = {
  getReport: async (params: advancedFilterModels.TableRestaurantAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/table-restaurant', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/table-restaurant/${id}`);

    return data;
  },

  add: async (params: models.TableRestaurant) => {
    const instance = await getInstance();
    const { data } = await instance.post('/table-restaurant', params);

    return data;
  },

  update: async (id: string, params: models.TableRestaurant) => {
    const instance = await getInstance();
    const { data } = await instance.put(`/table-restaurant/${id}`, params);
    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`/table-restaurant/${id}`);

    return data;
  },
};

export default TableRestaurantApi;
