import getInstance from './instance';

const RestaurantApi = {
  getReport: async (params: advancedFilterModels.RestaurantAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/store/gastronomy-meal', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/store/${id}`);

    return data;
  },

  getAll: async (params?: advancedFilterModels.RestaurantAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/store/gastronomy-meal/all', { params });

    return data;
  },

  getGastronomyAll: async (params?: advancedFilterModels.RestaurantAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/store/gastronomy/all', { params });

    return data;
  },

  getMealAll: async (params?: advancedFilterModels.RestaurantAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/store/meal/all', { params });

    return data;
  },
};

export default RestaurantApi;
