/* tslint:disable:object-literal-sort-keys */
const ptBr = {
  APPLICATION: {
    CURRENCY: 'BRL',
    ERRORS: {
      GENERIC: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      INVALID_KEY: 'Key do i18n inválida ou inexistente.',
      INSTANCE: {
        REFRESH_TOKEN: 'Token expirado. Faça Login novamente!',
        LOGIN: 'Faça Login novamente!',
      },
      USERS_NOT_FOUND: 'Não foi possível encontrar lista de usuários',
      TOKENS_NOT_SEND: 'Notificação não enviada',
      ENTITY_NOT_FOUND: 'Não foi possível encontrar o item buscado',
      USER_ALREADY_EXISTS: 'Usuário já existe',
      TABLE_RESTAURANT_ALREADY_EXISTS: 'Mesa já existe',
      READ_SPREADSHEET_ERROR: 'Não foi possível ler a planilha',
      FAILURE_PUSH_LIST: `Ops! Parece que alguns CPF’s não receberam a notificação.`,
      CATEGORY_ALREADY_EXISTS: 'Categoria já existe',
      SERVICES_ERROR: 'Não foi possível carregar a listagem de serviços',
      EVENTS_ERROR: 'Não foi possível carregar a listagem de eventos',
      CAMPAIGNS_PROMOTIONS_ERROR: 'Não foi possível carregar a listagem de campanhas/promoções',
      STORE_ERROR: 'Não foi possível carregar a listagem de lojas',
      GASTRONOMY_STORE_ERROR: 'Não foi possível carregar a listagem de lojas de gastronomia',
      MEAL_STORE_ERROR: 'Não foi possível carregar a listagem de lojas de alimentação',
      BUCKET_CREATE_ERROR: 'Não foi possível armazenar arquivo',
      BUCKET_UPDATE_ERROR: 'Não foi possível atualizar arquivo',
    },
    LANG: 'pt-BR',
  },
  COMPONENTS: {
    ADVANCED_FILTER: {
      FILTER: 'Filtrar',
      CLEAN: 'limpar',
    },
    ADVANCED_FILE_UPLOAD: {
      ERROR: 'Por favor insira um arquivo que pertença em um dos seguintes formatos: png, jpg/jpeg, pdf, mp3, mp4, wav, flv, wmv',
      FILENAME: 'Nome do arquivo:',
    },
    SEARCH_BAR: {
      SEARCH: {
        LABEL: 'o que deseja procurar?',
      },
      STATUS: {
        LABEL: 'STATUS',
      },
      FILTERS: {
        LABEL: 'FILTROS',
      },
    },
    DATA_TABLE_ACTIONS: {
      ADD: {
        LABEL: 'Adicionar',
      },
      EDIT: {
        LABEL: 'Editar',
      },
      VIEW: {
        LABEL: 'Visualizar',
      },
      DELETE: {
        LABEL: 'Deletar',
      },
      PRINT: {
        LABEL: 'Imprimir',
      },
      OPEN: {
        LABEL: 'Abrir',
      },
      HISTORY: {
        LABEL: 'Histórico',
      },
    },
    PANEL_CONTENT_TOP_BAR: {
      PAGES: {
        DASHBOARD: {
          TITLE: 'Dashboard',
          LOGOUT: 'Sair',
        },
      },
    },
  },
  PAGES: {
    AUTH: {
      LOGIN: {
        URL: '/',
        PAGE_TITLE: 'Entrar',
        MESSAGES: {
          LOGOUT: 'Você saiu do sistema.',
          WELCOME: 'Bem vindo(a) novamente.',
          INVALID: 'Email e/ou senha incorretos.',
        },
        FORM: {
          TITLE: 'ENTRAR',
          MESSAGE:
            'Ut sit amet ultricies turpis, sed molestie eros. Praesent magna neque, elementum non semper vitae, vestibulum vitae mi.',
          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          PASSWORD: {
            LABEL: 'Senha',
            PLACEHOLDER: 'Seu senha segura',
          },
          BUTTON: {
            TEXT: 'Entrar',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Esqueceu sua senha?',
            TEXT_LINK: 'Clique aqui',
          },
        },
      },
      PASSWORD_RECOVERY: {
        URL: '/recuperar-senha',
        PAGE_TITLE: 'Recuperar senha',
        MESSAGES: {
          WELCOME: 'Bem vindo(a) novamente.',
          LOGOUT: 'Você saiu do sistema.',
          EMAIL: 'Verifique seu email',
          RECOVERY_SUCCESS: 'Senha alterada com sucesso',
        },
        FORM: {
          TITLE: 'RECUPERAR SENHA',
          MESSAGE:
            'Iremos enviar os próximos passos no e-mail abaixo para prosseguir com a recuperação de sua senha',
          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          TOKEN: {
            LABEL: 'Código de recuperação',
          },
          PASSWORD: {
            LABEL: 'Sua nova senha',
          },
          CHANGE_PASSWORD: {
            LABEL: 'Alterar senha',
          },
          BUTTON: {
            TEXT: 'Enviar e-mail',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Para voltar para o login,',
            TEXT_LINK: 'Clique aqui',
          },
        },
      },
    },
    PANEL: {
      DASHBOARD: {
        TITLE: 'DASHBOARD',

        DETAILS: {
          WELCOME: 'Seja Bem Vindo ',
          TITLE: 'Informações gerais',
          PAGE_DESCRIPTION: 'Visualize alguns dos principais indicadores',

          CARD: {
            TABLE_RESTAURANT: 'Mesa(s) em seu restaurante',
            RESERVATION: 'Reserva(s) em seu restaurante',
          },

          CALENDAR: {
            APPOINTMENT: {
              RESTAURANT: 'Reserva(s)',
            },
          },
        },
      },

      ADMIN: {
        ROUTE: {
          NAME: 'ADMINISTRADORES',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/usuarios/administradores/detalhes/',
        },
        TITLE: 'ADMINISTRADORES',
        REPORT: {
          TITLE: 'Administradores',
          PAGE_TITLE: 'Administradores',
          PAGE_DESCRIPTION: 'Listagem de administradores',
          PAGE_TITLE_DETAILS: 'Detalhes',

          ADVANCED_FILTER: {
            NAME: 'Nome',
            EMAIL: 'E-mail',
          },

          TABLE: {
            NAME: {
              FIELD: 'name',
              HEADER: 'Nome'
            },
            EMAIL: {
              FIELD: 'email',
              HEADER: 'E-mail',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar administrador',
          TITLE_EDIT: 'Editar administrador',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um administrador',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um administrador',
          SUCCESS_ADD_MESSAGE: 'Administrador criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Administrador editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Administrador removido com sucesso!',
          FORM: {
            ERROR: {
              PASSWORD: 'Verifique se as senhas são iguais e tente novamente!',
              PASSWORD_EMPTY: 'Preencha o campo senha!',
              NAME: 'Preencha o nome!',
              PHONE: 'Preencha um número de telefone!',
              EMAIL: 'Preencha o e-mail!',
            },
            TITLE: 'Detalhes do administrador',
            NAME: {
              LABEL: 'Nome completo',
            },
            EMAIL: {
              LABEL: 'E-mail válido',
            },
            DDI: {
              LABEL: 'DDI'
            },
            PHONE: {
              LABEL: 'Telefone celular válido',
            },
            PASSWORD: {
              LABEL: 'Senha segura',
            },
            PASSWORD_CONFIRMATION: {
              LABEL: 'Digite novamente a senha',
            },
            CHANGE_PASSWORD: {
              LABEL: 'Desejo alterar a senha',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      CATEGORY: {
        ROUTE: {
          NAME: 'CATEGORIAS',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/comercial/categorias/detalhes/',
        },
        TITLE: 'CATEGORIAS',
        REPORT: {
          TITLE: 'Categorias',
          PAGE_TITLE: 'Categorias',
          PAGE_DESCRIPTION: 'Listagem de categorias',
          PAGE_TITLE_DETAILS: 'Detalhes',
      
          ADVANCED_FILTER: {
            TITLE: 'Título',
          },
      
          TABLE: {
            TITLE: {
              FIELD: 'title',
              HEADER: 'Título'
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },
      
        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar categoria',
          TITLE_EDIT: 'Editar categoria',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar uma categoria',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar uma categoria',
          SUCCESS_ADD_MESSAGE: 'Categoria criada com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Categoria editada com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Categoria removida com sucesso!',
          FORM: {
            ERROR: {
              TITLE: 'Preencha o título!',
            },
            TITLE: 'Detalhes da categoria',
            SUBJECT: {
              LABEL: 'Título',
            },
            PRODUCTS: {
              REPORT: 'Listagem de produtos desta categoria',
              DETAILS: 'Detalhes',
              TITLE: {
                LABEL: 'Nome do produto',
              },
              PRICE: {
                LABEL: 'Preço',
              },
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      PRODUCT: {
        ROUTE: {
          NAME: 'PRODUTOS',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/comercial/produtos/detalhes/',
        },
        TITLE: 'PRODUTOS',
        REPORT: {
          TITLE: 'Produtos',
          PAGE_TITLE: 'Produtos',
          PAGE_DESCRIPTION: 'Listagem de produtos',
          PAGE_TITLE_DETAILS: 'Detalhes',
      
          ADVANCED_FILTER: {
            TITLE: 'Título',
            PRICE: 'Preço',
          },
      
          TABLE: {
            TITLE: {
              FIELD: 'title',
              HEADER: 'Título'
            },
            PRICE: {
              FIELD: 'price',
              HEADER: 'Preço',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },
      
        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar produto',
          TITLE_EDIT: 'Editar produto',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um produto',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um produto',
          SUCCESS_ADD_MESSAGE: 'Produto criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Produto editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Produto removido com sucesso!',
          FORM: {
            ERROR: {
              TITLE: 'Preencha o título!',
              PRICE: 'Preencha o preço!',
              CATEGORY: 'Preencha a categoria a que pertence!',
              PICURL: 'Imagem não selecionada'
            },
            TITLE: 'Detalhes do produto',
            SUBJECT: {
              LABEL: 'Título',
            },
            PICURL: {
              LABEL: 'Upload de imagem'
            },
            PRICE: {
              LABEL: 'Preço',
            },
            CATEGORY: {
              LABEL: 'Categoria',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            },
            UPLOAD: {
              LABEL: 'Carregar imagem',
            },
          },
        },
      },

      PUSH: {
        ROUTE: {
          NAME: 'PUSH NOTIFICATIONS',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/comercial/notificacoes/detalhes/',
        },
        TITLE: 'PUSH NOTIFICATIONS',
        REPORT: {
          TITLE: 'Notificações',
          PAGE_TITLE: 'Notificações',
          PAGE_DESCRIPTION: 'Listagem de notificações',
          PAGE_TITLE_DETAILS: 'Detalhes',

          ADVANCED_FILTER: {
            TITLE: 'Título',
            SENDDATE: 'Data de envio',
          },

          TABLE: {
            TITLE: {
              FIELD: 'title',
              HEADER: 'Titulo'
            },
            STATUS: {
              FIELD: 'status',
              HEADER: 'Situação'
            },
            SENDDATE: {
              FIELD: 'sendDate',
              HEADER: 'Data de envio',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            },
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar notificação',
          TITLE_EDIT: 'Editar notificação',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar uma notificação',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar uma notificação',
          SUCCESS_ADD_MESSAGE: 'Notificação criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Notificação editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Notificação removido com sucesso!',
          FORM: {
            ERROR: {
              TITLE: 'Preencha o título!',
              BODY: 'Preencha a descrição!',
              SENDDATE: 'Preencha o data de envio!',
              SEGMENTATIONLIST: `Preencha com a listagem de CPF's!`,
              SEGMENTATIONFILE: 'Preencha selecionando o arquivo!',
            },
            TITLE: 'Detalhes da notificação',
            SUBJECT: {
              LABEL: 'Título',
            },
            BODY: {
              LABEL: 'Descrição',
            },
            SENDDATE: {
              LABEL: 'Data de envio',
              PLACEHOLDER: 'dd/mm/aaaa hh:mm',
            },
            IS_SCHEDULE: {
              LABEL: 'Agendar'
            },
            IS_SEGMENTED: {
              LABEL: 'Segmentado'
            },
            REDIRECT_TYPE: {
              LABEL: 'Redirecionamento da notificação',
              OPTIONS: {
                NONE: 'Nenhum redirecionamento',
              },
            },
            SEGMENTATION: {
              DOCUMENT: {
                LABEL: `CPF's`,
                TYPE: {
                  LABEL: 'Tipo de segmentação',
                },
                TEXT: {
                  TITLE: 'Texto',
                  LABEL: `Listagem dos CPF's`,
                  POPOVER: `O CPF deve ser colocado um por linha, sem formatação nenhuma ou com formatação completa (xxx.xxx.xxx.-xx)`,
                },
                FILE: {
                  TITLE: 'Arquivo',
                  LABEL: 'Selecione o arquivo',
                  POPOVER: `O arquivo deve ser .xls com no máximo 17.000 linhas no total do arquivo`,
                },
              }
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      COUPON: {
        ROUTE: {
          NAME: 'COUPON',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/comercial/cupons/detalhes/',
        },
        TITLE: 'COUPON',
        REPORT: {
          TITLE: 'Cupons',
          PAGE_TITLE: 'Cupons',
          PAGE_DESCRIPTION: 'Listagem de cupons',
          PAGE_TITLE_DETAILS: 'Detalhes',

          ADVANCED_FILTER: {
            TITLE: 'Título',
          },

          TABLE: {
            TITLE: {
              FIELD: 'title',
              HEADER: 'Titulo'
            },
            VALUE: {
              FIELD: 'value',
              HEADER: 'Valor'
            },
            DISCOUNT_TYPE: {
              FIELD: 'discountType',
              HEADER: 'Tipo de desconto',
            },
            START_AT: {
              FIELD: 'startAt',
              HEADER: 'Ínicio',
            },
            END_AT: {
              FIELD: 'endAt',
              HEADER: 'Término'
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            },
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar cupom',
          TITLE_EDIT: 'Editar cupom',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar uma cupom',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar uma cupom',
          SUCCESS_ADD_MESSAGE: 'Cupom criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Cupom editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Cupom removido com sucesso!',
          FORM: {
            ERROR: {
              TITLE: 'Preencha o título!',
              VALUE: 'Preencha o valor!',
              INITIAL_QUANTITY: 'Preencha a quantidade inicial!',
              VALIDITY: 'Preencha o período de validade!',
            },
            TITLE: 'Detalhes do cupom',
            CODE: {
              LABEL: 'Título',
            },
            VALUE: {
              LABEL: 'Valor',
            },
            PERCENTAGE: {
              LABEL: 'Porcentagem',
            },
            DISCOUNT_TYPE: {
              LABEL: 'Tipo de desconto',
              VALUE: {
                LABEL: 'Valor R$',
              },
              PERCENTAGE: {
                LABEL: 'Porcentagem %'                
              }
            },
            VALIDITY: {
              LABEL: 'Periodo',
            },
            START_AT: {
              LABEL: 'Ínicio',
            },
            END_AT: {
              LABEL: 'Término',
            },
            QUANTITY: {
              LABEL: 'Informar quantidade de cupons',
            },
            CURRENT_QUANTITY: {
              LABEL: 'Quantidade atual',
            },
            INITIAL_QUANTITY: {
              LABEL: 'Quantidade inicial',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      RESTAURANT: {
        ROUTE: {
          NAME: 'GASTRONOMIA',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/estabelecimentos/restaurantes/detalhes/',
        },
        TITLE: 'GASTRONOMIA',
        REPORT: {
          TITLE: 'Gastronomia',
          PAGE_TITLE: 'Gastronomia',
          PAGE_DESCRIPTION: 'Listagem de restaurantes',
          PAGE_TITLE_DETAILS: 'Detalhes',
      
          ADVANCED_FILTER: {
            TRADENAME: 'Nome comercial',
            FLOOR: 'Piso',
          },
      
          TABLE: {
            TRADENAME: {
              FIELD: 'tradeName',
              HEADER: 'Nome comercial'
            },
            FLOOR: {
              FIELD: 'floor',
              HEADER: 'Piso',
            },
            PHONE: {
              FIELD: 'phone',
              HEADER: 'Telefone',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },
      
        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar restaurante',
          TITLE_EDIT: 'Editar restaurante',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um restaurante',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um restaurante',
          SUCCESS_ADD_MESSAGE: 'Restaurante criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Restaurante editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Restaurante removido com sucesso!',
          FORM: {
            TITLE: 'Detalhes do restaurante',
            TRADENAME: {
              LABEL: 'Nome comercial',
            },
            SITE: {
              LABEL: 'Site',
            },
            SEGMENT: {
              LABEL: 'Segmento',
            },
            PHONE: {
              LABEL: 'Telefone'
            },
            WHATSAPP: {
              LABEL: 'Whatsapp',
            },
            EMAIL: {
              LABEL: 'E-mail',
            },
            FLOOR: {
              LABEL: 'Piso',
            },
            OPEN_HOUR: {
              LABEL: 'Horário de abertura',
            },
            CLOSE_HOUR: {
              LABEL: 'Horário de encerramento',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      BANNER: {
        ROUTE: {
          NAME: 'POSTER',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/poster/banner/detalhes/',
        },
        TITLE: 'BANNERS',
        REPORT: {
          TITLE: 'Banners',
          PAGE_TITLE: 'Banners',
          PAGE_DESCRIPTION: 'Listagem de banners',
          PAGE_TITLE_DETAILS: 'Detalhes',

          ADVANCED_FILTER: {
            TITLE: 'Título',
            BADGE: 'Rótulo',
          },

          TABLE: {
            TITLE: {
              FIELD: 'title',
              HEADER: 'Título',
            },
            BADGE: {
              FIELD: 'badge',
              HEADER: 'Rótulo',
            },
            ORDER: {
              FIELD: 'order',
              HEADER: 'Ordem de exibição',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            },
          },
        },
        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar banner',
          TITLE_EDIT: 'Editar banner',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um banner',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um banner',
          SUCCESS_ADD_MESSAGE: 'Banner criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Banner editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Banner removido com sucesso!',
          FORM: {
            ERROR: {
              TITLE: 'Preencher título!',
              DESCRIPTION: 'Preencher descrição!',
              BADGE: 'Preencher o rótulo!',
              THUMBNAIL_IMAGE: 'Escolher a imagem thumbnail!',
              MAIN_IMAGE: 'Escolher a imagem principal!',
              CONTENT: 'Preencher contéudo associado!',
            },
            HEADER: 'Detalhes do banner',
            TITLE: {
              LABEL: 'Título'
            },
            DESCRIPTION: {
              LABEL: 'Descrição',
            },
            BADGE: {
              LABEL: 'Rotúlo',
            },
            ORDER: {
              LABEL: 'Ordem de exibição',
            },
            CONTENT_TYPE: {
              LABEL: 'Tipo de contéudo associado',
            },
            CONTENT: {
              LABEL: 'Conteúdo associado',
            },
            VALIDATE_INVOICE: {
              LABEL: 'Validar nota fiscal?'
            },
            THUMBNAIL_IMAGE: {
              LABEL: 'Imagem thumbnail',
            },
            MAIN_IMAGE: {
              LABEL: 'Imagem principal',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            },
            UPLOAD: {
              LABEL: 'Carregar imagem',
            }, 
            UPLOAD_FILE: {
              LABEL: 'Carregar arquivo',
            },
            ATTACHMENT: {
              LABEL: 'Anexos',
            },
          },
        },

        CAMPAIGN: {
          ROUTE: {
            NAME: 'POSTER',
            REPORT: 'RELATÓRIO',
            REGISTER: 'REGISTRO',
            DETAILS: '/poster/campanha-promocao/detalhes/',
          },
          TITLE: 'CAMPAIGN',
          REPORT: {
            TITLE: 'Campanhas/Promoções',
            PAGE_TITLE: 'Campanhas/Promoções',
            PAGE_DESCRIPTION: 'Listagem de banners de campanhas/promoções',
            PAGE_TITLE_DETAILS: 'Detalhes',
  
            ADVANCED_FILTER: {
              TITLE: 'Título',
              BADGE: 'Rótulo',
            },
  
            TABLE: {
              TITLE: {
                FIELD: 'title',
                HEADER: 'Título',
              },
              BADGE: {
                FIELD: 'badge',
                HEADER: 'Rótulo',
              },
              ORDER: {
                FIELD: 'order',
                HEADER: 'Ordem de exibição',
              },
              CREATED: {
                FIELD: 'createdAt',
                HEADER: 'Data de cadastro'
              },
              ACTIONS: {
                FIELD: 'actions',
                HEADER: 'Ações'
              },
            },
          },
          DETAILS: {
            UNDEFINED: 'Não definido',
            TITLE_ADD: 'Adicionar banner de campanhas/promoções',
            TITLE_EDIT: 'Editar banner de campanhas/promoções',
            DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um banner de campanhas/promoções',
            DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um banner de campanhas/promoções',
            SUCCESS_ADD_MESSAGE: 'Banner de campanhas/promoções criado com sucesso!',
            SUCCESS_EDIT_MESSAGE: 'Banner de campanhas/promoções editado com sucesso!',
            SUCCESS_REMOVE_MESSAGE: 'Banner de campanhas/promoções removido com sucesso!',
            FORM: {
              ERROR: {
                TITLE: 'Preencher título!',
                DESCRIPTION: 'Preencher descrição!',
                BADGE: 'Preencher o rótulo!',
                THUMBNAIL_IMAGE: 'Escolher a imagem thumbnail!',
                MAIN_IMAGE: 'Escolher a imagem principal!',
                CONTENT: 'Preencher o contéudo associado!',
              },
              HEADER: 'Detalhes do banner de campanhas/promoções',
              TITLE: {
                LABEL: 'Título'
              },
              DESCRIPTION: {
                LABEL: 'Descrição',
              },
              BADGE: {
                LABEL: 'Rotúlo',
              },
              ORDER: {
                LABEL: 'Ordem de exibição',
              },
              CONTENT: {
                LABEL: 'Conteúdo associado',
              },
              VALIDATE_INVOICE: {
                LABEL: 'Validar nota fiscal?'
              },
              THUMBNAIL_IMAGE: {
                LABEL: 'Imagem thumbnail',
              },
              MAIN_IMAGE: {
                LABEL: 'Imagem principal',
              },
              BACK: {
                LABEL: 'VOLTAR',
              },
              SUBMIT: {
                LABEL: 'Salvar'
              },
              UPLOAD: {
                LABEL: 'Carregar imagem',
              }, 
              UPLOAD_FILE: {
                LABEL: 'Carregar arquivo',
              },
              ATTACHMENT: {
                LABEL: 'Anexos',
              },
            },
          },
        },
      },

      SHOPKEEPER: {
        ROUTE: {
          NAME: 'LOJISTAS',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/usuarios/lojistas/detalhes/',
        },
        TITLE: 'LOJISTAS',
        REPORT: {
          TITLE: 'Lojistas',
          PAGE_TITLE: 'Lojistas',
          PAGE_DESCRIPTION: 'Listagem de lojistas',
          PAGE_TITLE_DETAILS: 'Detalhes',
      
          ADVANCED_FILTER: {
            NAME: 'Nome',
            EMAIL: 'E-mail',
          },
      
          TABLE: {
            NAME: {
              FIELD: 'name',
              HEADER: 'Nome'
            },
            EMAIL: {
              FIELD: 'email',
              HEADER: 'E-mail',
            },
            PROFILE_TYPE: {
              FIELD: 'profileType',
              HEADER: 'Perfil',
            },
            STORE_IDENTIFIER: {
              FIELD: 'storeIdentifier',
              HEADER: 'Identificador da loja',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },
      
        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar lojista',
          TITLE_EDIT: 'Editar lojista',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar um lojista',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar um lojista',
          SUCCESS_ADD_MESSAGE: 'Lojista criado com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Lojista editado com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Lojista removido com sucesso!',
          FORM: {
            ERROR: {
              PASSWORD: 'Verifique se as senhas são iguais e tente novamente!',
              PASSWORD_EMPTY: 'Preencha o campo senha!',
              NAME: 'Preencha o nome!',
              PHONE: 'Preencha um número de telefone!',
              EMAIL: 'Preencha o e-mail!',
              PROFILE_TYPE: 'Preencha o campo perfil do usuário lojista!',
              STORE_IDENTIFIER: 'Preencha o campo de loja/restaurante!',
            },
            TITLE: 'Detalhes do lojista',
            NAME: {
              LABEL: 'Nome completo',
            },
            EMAIL: {
              LABEL: 'E-mail válido',
            },
            DDI: {
              LABEL: 'DDI'
            },
            PHONE: {
              LABEL: 'Telefone celular válido',
            },
            PASSWORD: {
              LABEL: 'Senha segura',
            },
            PASSWORD_CONFIRMATION: {
              LABEL: 'Digite novamente a senha',
            },
            CHANGE_PASSWORD: {
              LABEL: 'Desejo alterar a senha',
            },
            PROFILE_TYPE: {
              LABEL: 'Perfil',
              OPTIONS: {
                DEFAULT: 'Escolher perfil do usuário',
                SHOPKEEPER: 'Lojista',
                RESTAURANT: 'Gastronomia',
              },
            },
            STORE: {
              LABEL: 'Loja',
            },
            RESTAURANT: {
              LABEL: 'Restaurante',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      STORE: {
        ROUTE: {
          NAME: 'LOJAS',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/estabelecimentos/lojas/detalhes/',
        },
        TITLE: 'LOJAS',
        REPORT: {
          TITLE: 'Lojas',
          PAGE_TITLE: 'Lojas',
          PAGE_DESCRIPTION: 'Listagem de lojas',
          PAGE_TITLE_DETAILS: 'Detalhes',
      
          ADVANCED_FILTER: {
            TRADENAME: 'Nome comercial',
            FLOOR: 'Piso',
          },
      
          TABLE: {
            TRADENAME: {
              FIELD: 'tradeName',
              HEADER: 'Nome comercial'
            },
            FLOOR: {
              FIELD: 'floor',
              HEADER: 'Piso',
            },
            PHONE: {
              FIELD: 'phone',
              HEADER: 'Telefone',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },
      
        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar loja',
          TITLE_EDIT: 'Editar loja',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar uma loja',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar uma loja',
          SUCCESS_ADD_MESSAGE: 'Loja criada com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Loja editada com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Loja removida com sucesso!',
          FORM: {
            TITLE: 'Detalhes da loja',
            TRADENAME: {
              LABEL: 'Nome comercial',
            },
            SITE: {
              LABEL: 'Site',
            },
            SEGMENT: {
              LABEL: 'Segmento',
            },
            PHONE: {
              LABEL: 'Telefone'
            },
            WHATSAPP: {
              LABEL: 'Whatsapp',
            },
            EMAIL: {
              LABEL: 'E-mail',
            },
            FLOOR: {
              LABEL: 'Piso',
            },
            OPEN_HOUR: {
              LABEL: 'Horário de abertura',
            },
            CLOSE_HOUR: {
              LABEL: 'Horário de encerramento',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      TABLE_RESTAURANT: {
        ROUTE: {
          NAME: 'MESAS DO RESTAURANTE',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/comercial/mesas-restaurante/detalhes/',
        },
        TITLE: 'MESAS DO RESTAURANTE',
        REPORT: {
          TITLE: 'Mesas do restaurante',
          PAGE_TITLE: 'Mesas do restaurante',
          PAGE_DESCRIPTION: 'Listagem das mesas do restaurante',
          PAGE_TITLE_DETAILS: 'Detalhes',
      
          ADVANCED_FILTER: {
            ORDER_SHEET: 'Comanda da mesa',
            RESERVED: 'Reservada',
          },
      
          TABLE: {
            ORDER_SHEET: {
              FIELD: 'orderSheet',
              HEADER: 'Comanda da mesa'
            },
            CHAIRS: {
              FIELD: 'chairsAmount',
              HEADER: 'Quantidade de cadeiras',
            },
            CATEGORY: {
              FIELD: 'category.title',
              HEADER: 'Categoria',
            },
            RESERVED: {
              FIELD: 'reserved',
              HEADER: 'Reservada?',
              OPTION: {
                TRUE: 'Sim',
                FALSE: 'Não',
              },
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },
      
        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar mesa do restaurante',
          TITLE_EDIT: 'Editar mesa do restaurante',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar uma mesa',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar uma mesa',
          SUCCESS_ADD_MESSAGE: 'Mesa criada com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Mesa editada com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Mesa removida com sucesso!',
          FORM: {
            ERROR: {
              ORDER_SHEET: 'Preencha com a comanda da mesa!',
              CHAIRS_AMOUNT: 'Preencha com a quantidade válida de cadeiras',
              AVAILABLE_PERIOD_MISSING: 'Preencha com algum período de disponibilidade',
              AVAILABLE_PERIOD_INVALID: 'Preencha com o período de disponibilidade válido'  
            },
            TITLE: 'Detalhes da mesa do restaurante',
            ORDER_SHEET: {
              LABEL: 'Comanda da mesa',
            },
            CHAIRS_AMOUNT: {
              LABEL: 'Quantidade de cadeiras',
            },
            CATEGORY: {
              LABEL: 'Categoria',
            },
            BREAKFAST: {
              START: {
                LABEL: 'Ínicio de disponibilidade - Café da manhã',
              },
              END: {
                LABEL: 'Fim de disponibilidade - Café da manhã'
              },
            },
            LUNCH: {
              START: {
                LABEL: 'Ínicio de disponibilidade - Almoço',
              },
              END: {
                LABEL: 'Fim de disponibilidade - Almoço'
              },
            },
            DINNER: {
              START: {
                LABEL: 'Ínicio de disponibilidade - Jantar',
              },
              END: {
                LABEL: 'Fim de disponibilidade - Jantar',
              },
            },
            RESERVATIONS: {
              REPORT: 'Reservas desta mesa',
              DETAILS: 'Detalhes',
              USER: {
                LABEL: 'Documento do cliente',
              },
              SCHEDULE_DATE: {
                LABEL: 'Data da reserva',
              },
              START: {
                LABEL: 'Horário de ínicio',
              },
              END: {
                LABEL: 'Horário de término',
              },
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      RESERVATION: {
        ROUTE: {
          NAME: 'RESERVAS DE MESA',
          REPORT: 'RELATÓRIO',
          REGISTER: 'REGISTRO',
          DETAILS: '/comercial/reservas/detalhes/',
        },
        TITLE: 'RESERVAS DE MESA',
        REPORT: {
          TITLE: 'Reservas de mesa',
          PAGE_TITLE: 'Reservas de mesa',
          PAGE_DESCRIPTION: 'Listagem das reservas de mesa',
          PAGE_TITLE_DETAILS: 'Detalhes',
      
          ADVANCED_FILTER: {
            SCHEDULE_DATE: 'Data da reserva',
          },
      
          TABLE: {
            TABLE_RESTAURANT: {
              FIELD: 'tableRestaurant.orderSheet',
              HEADER: 'Comanda da mesa'
            },
            USER: {
              FIELD: 'customer.document',
              HEADER: 'Documento do cliente',
            },
            SCHEDULE_DATE: {
              FIELD: 'scheduleDate',
              HEADER: 'Data da reserva',
            },
            START: {
              FIELD: 'start',
              HEADER: 'Horário de ínicio'
            },
            END: {
              FIELD: 'end',
              HEADER: 'Horário de término',
            },
            CREATED: {
              FIELD: 'createdAt',
              HEADER: 'Data de cadastro'
            },
            ACTIONS: {
              FIELD: 'actions',
              HEADER: 'Ações'
            }
          },
        },
      
        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE_ADD: 'Adicionar reserva de mesa',
          TITLE_EDIT: 'Editar reserva de mesa',
          DESCRIPTION_ADD: 'Preencha os campos abaixo para adicionar uma reserva de mesa',
          DESCRIPTION_EDIT: 'Preencha os campos abaixo para editar uma reserva de mesa',
          SUCCESS_ADD_MESSAGE: 'Reserva de mesa criada com sucesso!',
          SUCCESS_EDIT_MESSAGE: 'Reserva de mesa editada com sucesso!',
          SUCCESS_REMOVE_MESSAGE: 'Reserva de mesa removida com sucesso!',
          FORM: {
            TITLE: 'Detalhes da reserva de mesa',
            TABLE_RESTAURANT: {
              ORDER_SHEET: {
                LABEL: 'Comanda da mesa',
              },
              CHAIRS_AMOUNT: {
                LABEL: 'Quantidade de cadeiras',
              },
            },
            USER: {
              NAME: {
                LABEL: 'Nome do cliente',
              },
              DOCUMENT: {
                LABEL: 'Documento',
              },
              PHONE: {
                LABEL: 'Telefone celular',
              },
              EMAIL: {
                LABEL: 'Email'
              },
            },
            SCHEDULE_DATE: {
              LABEL: 'Data da reserva',
            },
            START: {
              LABEL: 'Horário de ínicio',
            },
            END: {
              LABEL: 'Horário de término',
            },
            BACK: {
              LABEL: 'VOLTAR',
            },
            SUBMIT: {
              LABEL: 'Salvar'
            }
          },
        },
      },

      NOT_FOUND: {
        TITLE: 'Erro 404',
        PAGE_DESCRIPTION:
          'Parece que não encontramos a página solicitada.\nVerifique a url digitada e tente novamente.',
      },
    },
  },

  SHARED: {
    CURRENCY_SYMBOL: 'R$',
    SELECT_SOMETHING: 'Selecione...',
    ADD_NEW: 'Adicionar',
    IMPORT_NEW: 'Importar CSV',
    SAVE: 'Salvar',
    ADD_ID: 'registro',
    CANCEL: 'Cancelar',
    DELETE: 'Deletar',
    DAY: 'Dia',
    MONTHS: {
      JANUARY: 'Jan',
      FEBRUARY: 'Fev',
      MARCH: 'Mar',
      APRIL: 'Abr',
      MAY: 'Mai',
      JUNE: 'Jun',
      JULY: 'Jul',
      AUGUST: 'Ago',
      SEPTEMBER: 'Set',
      OCTOBER: 'Out',
      NOVEMBER: 'Nov',
      DECEMBER: 'Dez',
    },
    DAYS_WEEK: {
      SUNDAY: 'D',
      MONDAY: 'S',
      TUESDAY: 'T',
      WEDNESDAY: 'Q',
      THURSDAY: 'Q',
      FRIDAY: 'S',
      SATURDAY: 'S',
    },
    DELETE_ACTION: {
      TITLE: 'Deseja realmente excluir esse item?',
      CONTENT: 'Esta ação é irreversível',
    },
    DATE_FORMAT: 'dd/MM/yyyy',
    DATETIME_FORMAT: 'dd/MM/yyyy hh:mm',
    SEND: 'Enviado',
    PENDING: 'Pendente',
    SCHEDULED: 'Agendado',
    CANCELED: 'Cancelado',
    PROFILE_TYPE: {
      ADMIN: 'Administrador',
      APP: 'Aplicativo',
      SHOPKEEPER: 'Lojista',
      RESTAURANT: 'Polo Gastronômico',
    },
    ENUMERATORS: {
      CONTENT_TYPE: {
        NONE: 'Nenhum',
        EVENT: 'Evento',
        CAMPAIGN_PROMOTION: 'Campanha/Promoção',
        STORE: 'Loja',
        SERVICE: 'Serviço',
        GASTRONOMY: 'Gastronomia',
        MEAL: 'Alimentação',
      },
      COUPON: {
        VALUE: 'Valor',
        PERCENTAGE: 'Porcentagem',
      },
    },
  },
};

export default ptBr;
