import { Dispatch } from 'redux';
import ProductRequests from '~/repositories/product';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { PRODUCT_DETAIL, PRODUCT_REPORT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

import { getProductMockReport, getProductMockDetail } from '../mock/product';

export const cleanProductDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: PRODUCT_DETAIL,
  });
};

export const getProductReport = (
  searchParams: advancedFilterModels.ProductAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Product> = getProductMockReport();

    dispatch({
      payload,
      type: PRODUCT_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getProductDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Product = getProductMockDetail(id);

    dispatch({
      payload,
      type: PRODUCT_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateProduct = (id: string, params: models.Product) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await ProductRequests.update(id, params);

    MessageService.success('PAGES.PANEL.PRODUCT.DETAILS.SUCCESS_EDIT_MESSAGE');
    centralNavigationService.back();

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const addProduct = (params: models.Product) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await ProductRequests.add(params);

    MessageService.success('PAGES.PANEL.PRODUCT.DETAILS.SUCCESS_ADD_MESSAGE');
    centralNavigationService.back();

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeProduct = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await ProductRequests.remove(id);
    MessageService.success('PAGES.PANEL.PRODUCT.DETAILS.SUCCESS_REMOVE_MESSAGE');

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};
