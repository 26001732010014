import getInstance from './instance';

const ProductApi = {
  getReport: async (params: advancedFilterModels.ProductAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/product', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/product/${id}`);

    return data;
  },

  add: async (params: models.Product) => {
    const instance = await getInstance();
    const { data } = await instance.post('/product', params);

    return data;
  },

  update: async (id: string, params: models.Product) => {
    const instance = await getInstance();
    const { data } = await instance.put(`/product/${id}`, params);
    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`/product/${id}`);

    return data;
  },
};

export default ProductApi;
