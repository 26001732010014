import React from 'react';

import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import DashboardRestaurantDetails from '~/pages/Panel/Dashboard/Details/Restaurant/DashboardRestaurantDetails';

import { translate } from '~/services/i18n';
import { useReduxState } from '~/hooks/useReduxState';
import { USER_PAGE_TYPE } from '~/enum/pageType';

const DashboardDetails: React.FC = () => {
  const { auth } = useReduxState();
  
  return (
    <div className="panel-dashboard">
      <div className="panel-dashboard__welcome">
        {translate('PAGES.PANEL.DASHBOARD.DETAILS.WELCOME')}
        {auth.me?.name}
      </div>
      <div className="panel-dashboard__title">
        <PanelContentHeader
          pageTitle={translate('PAGES.PANEL.DASHBOARD.DETAILS.TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION'
          )}
        />
      </div>
      {auth.me?.profileType === USER_PAGE_TYPE.RESTAURANT && (
        <DashboardRestaurantDetails />
      )}
    </div>
  );
};

export default DashboardDetails;
