import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import ReservationReport from '~/pages/Panel/Reservation/Report/ReservationReport';
import ReservationDetails from '~/pages/Panel/Reservation/Details/ReservationDetails';

const ReservationNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('COMMERCIAL', 'RESERVATION_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.RESERVATION.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.RESERVATION.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <ReservationReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'RESERVATION_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.RESERVATION.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.RESERVATION.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <ReservationDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default ReservationNavigationStack;
