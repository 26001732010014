import { LeftOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import * as ReservationActions from '~/actions/reservation';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import { USER_PAGE_TYPE, PAGE_TYPE } from '~/enum/pageType';
import { getRouteStackPath } from '~/config/routes';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';
import { maskCpfOrCnpj, maskHour, maskPhone } from '~/services/masks';

const formInitialValues: models.Reservation = {
  restaurantId: '',
  userId: '',
  tableRestaurantId: '',
  scheduleDate: '',
  start: '',
  end: '',
};

const ReservationDetails: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);

  const { pathname } = useLocation();
  const { reservation, auth } = useReduxState();

  useEffect(() => {
    if (reservation && reservation.detail) {
      setForm({
        ...reservation.detail,
        scheduleDate: DateTime.fromISO(reservation?.detail?.scheduleDate as string).toLocaleString(
          DateTime.DATE_SHORT
        ),
        start: DateTime.fromISO(reservation?.detail?.start as string).toLocaleString(
          DateTime.TIME_SIMPLE
        ),
        end: DateTime.fromISO(reservation?.detail?.end as string).toLocaleString(
          DateTime.TIME_SIMPLE
        ),
      });
    } else {
      setForm({
        ...formInitialValues,
        restaurantId: auth.me?.storeIdentifier || '',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(ReservationActions.cleanReservationDetail());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  useEffect(() => {
    if (auth.me?.profileType !== USER_PAGE_TYPE.RESTAURANT) {
      window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    }
  }, [auth]);

  return (
    <div className="reservation">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.RESERVATION.REPORT.TITLE'),
                url: getRouteStackPath('COMMERCIAL', 'RESERVATION_REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.RESERVATION.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="reservation__details__form">
            <div className="reservation__details__form__title">
              <h3 className="reservation__details__form__title__text">
                {translate('PAGES.PANEL.RESERVATION.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={() => {}}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESERVATION.DETAILS.FORM.TABLE_RESTAURANT.ORDER_SHEET.LABEL')}
                    value={form.tableRestaurant?.orderSheet}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESERVATION.DETAILS.FORM.TABLE_RESTAURANT.CHAIRS_AMOUNT.LABEL')}
                    value={form.tableRestaurant?.chairsAmount}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AdvancedInput
                    value={form.user?.name}
                    label={translate(
                      'PAGES.PANEL.RESERVATION.DETAILS.FORM.USER.NAME.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    value={form.user?.email}
                    label={translate(
                      'PAGES.PANEL.RESERVATION.DETAILS.FORM.USER.EMAIL.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AdvancedInput
                    value={form.user?.document && maskCpfOrCnpj(form.user.document)}
                    label={translate(
                      'PAGES.PANEL.RESERVATION.DETAILS.FORM.USER.DOCUMENT.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    value={form.user?.phone && maskPhone(form.user.phone)}
                    label={translate(
                      'PAGES.PANEL.RESERVATION.DETAILS.FORM.USER.PHONE.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AdvancedInput
                    value={form.scheduleDate}
                    label={translate(
                      'PAGES.PANEL.RESERVATION.DETAILS.FORM.SCHEDULE_DATE.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={2}>
                  <AdvancedInput
                    value={maskHour(form.start as string)}
                    label={translate(
                      'PAGES.PANEL.RESERVATION.DETAILS.FORM.START.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={2}>
                  <AdvancedInput
                    value={maskHour(form.end as string)}
                    label={translate(
                      'PAGES.PANEL.RESERVATION.DETAILS.FORM.END.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>
              
              <Row>
                <Col md={8}>
                  <AdvancedButton
                    variant="text"
                    text={translate(
                      'PAGES.PANEL.RESERVATION.DETAILS.FORM.BACK.LABEL'
                    )}
                    onClick={() => history.goBack()}
                    startIcon={<LeftOutlined />}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ReservationDetails;
