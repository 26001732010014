import { SaveOutlined, LeftOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

import * as CouponActions from '~/actions/coupon';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedDateRangePicker from '~/components/AdvancedDateRangePicker/AdvancedDateRangePicker';
import AdvancedRadio from '~/components/AdvancedRadio/AdvancedRadio';
import AdvancedCheckbox from '~/components/AdvancedCheckbox/AdvancedCheckbox';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import { PAGE_TYPE } from '~/enum/pageType';
import { COUPON_DISCOUNT_TYPE } from '~/enum/coupon';
import { getRouteStackPath } from '~/config/routes';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';;

const formInitialValues: models.Coupon = {
  storeIdentifier: '',
  title: '',
  discountType: COUPON_DISCOUNT_TYPE.VALUE,
  value: 0,
  initialQuantity: 0,
  currentQuantity: 0,
  startAt: '',
  endAt: '',
  validity: [],
};

const CouponDetails: React.FC = (props) => {
  const dispatch = useDispatch();

  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);
  const [showQuantity, setShowQuantity] = useState(false);

  const { pathname } = useLocation();
  const { coupon, auth } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.Coupon) => ({ ...prevState, [key]: val }));
  };

  const onValidityChange = (validity: Array<any>) => {
    onFormChange('validity', validity);
    onFormChange('startAt', validity[0].format('YYYY-MM-DD'));
    onFormChange('endAt', validity[1].format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (coupon && coupon.detail) {
      setForm({
        ...coupon.detail,
        validity: [
          moment(coupon.detail.startAt, 'YYYY-MM-DD'),
          moment(coupon.detail.endAt, 'YYYY-MM-DD'),
        ],
      });
      setShowQuantity(coupon.detail.initialQuantity > 0 ? true : false);
    } else {
      setForm({
        ...formInitialValues,
        storeIdentifier: auth.me?.storeIdentifier || null,
      });
      setShowQuantity(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupon.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(CouponActions.cleanCouponDetail());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);


  const onFormSubmit = () => {
    const requestForm: any = {
      storeIdentifier: form.storeIdentifier,
      title: form.title,
      value: form.value,
      discountType: form.discountType,
      initialQuantity: form.initialQuantity,
      currentQuantity: form.currentQuantity,
      startAt: form.startAt,
      endAt: form.endAt,
    };

    if (!form.title) {
      return MessageService.error('PAGES.PANEL.COUPON.DETAILS.FORM.ERROR.TITLE');
    }

    if (!form.value) {
      return MessageService.error('PAGES.PANEL.COUPON.DETAILS.FORM.ERROR.VALUE');
    }

    if (showQuantity && !form.initialQuantity) {
      return MessageService.error('PAGES.PANEL.COUPON.DETAILS.FORM.ERROR.INITIAL_QUANTITY');
    }

    if (!form.startAt || !form.endAt) {
      return MessageService.error('PAGES.PANEL.COUPON.DETAILS.FORM.ERROR.VALIDITY');
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(CouponActions.updateCoupon(coupon?.detail?.id as string, requestForm));
    } else {
      dispatch(CouponActions.addCoupon(requestForm));
    }
  };

  return (
    <div className="coupon">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.COUPON.REPORT.TITLE'),
                url: getRouteStackPath('COMMERCIAL', 'COUPON_REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.COUPON.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="coupon__details__form">
            <div className="coupon__details__form__title">
              <h3 className="coupon__details__form__title__text">
                {translate('PAGES.PANEL.COUPON.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.COUPON.DETAILS.FORM.CODE.LABEL')}
                    value={form.title}
                    onChange={(val: string) => onFormChange('title', val)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={
                      translate(
                        `PAGES.PANEL.COUPON.DETAILS.FORM.${form.discountType === COUPON_DISCOUNT_TYPE.VALUE ? 'VALUE' : 'PERCENTAGE'}.LABEL`
                      )
                    }
                    value={form.value}
                    type="number"
                    onChange={(val: number) => onFormChange('value', val)}
                    inputProps={{ 
                      min: 0, 
                      max: form.discountType === COUPON_DISCOUNT_TYPE.VALUE ? undefined : 100,
                    }}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AdvancedRadio
                    label={translate('PAGES.PANEL.COUPON.DETAILS.FORM.DISCOUNT_TYPE.LABEL')}
                    value={form.discountType}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: number) => {
                      onFormChange('discountType', val);
                      onFormChange('value', 0);
                    }}
                    items={[
                      {
                        label: translate('PAGES.PANEL.COUPON.DETAILS.FORM.DISCOUNT_TYPE.VALUE.LABEL'),
                        value: COUPON_DISCOUNT_TYPE.VALUE,
                      },
                      {
                        label: translate('PAGES.PANEL.COUPON.DETAILS.FORM.DISCOUNT_TYPE.PERCENTAGE.LABEL'),
                        value: COUPON_DISCOUNT_TYPE.PERCENTAGE,
                      }
                    ]}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedCheckbox 
                    value={showQuantity}
                    label={translate('PAGES.PANEL.COUPON.DETAILS.FORM.QUANTITY.LABEL')}
                    onChange={(val: boolean) => {
                      setShowQuantity(val);

                      if (!val) {
                        onFormChange('initialQuantity', 0);
                        onFormChange('currentQuantity', 0);
                      }
                    }}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <AdvancedDateRangePicker
                    format={translate('SHARED.DATE_FORMAT')}
                    label={translate('PAGES.PANEL.COUPON.DETAILS.FORM.VALIDITY.LABEL')}
                    value={form.validity}
                    onChange={(val: any) => onValidityChange(val)}
                    placeholder={[
                      translate('PAGES.PANEL.COUPON.DETAILS.FORM.START_AT.LABEL'),
                      translate('PAGES.PANEL.COUPON.DETAILS.FORM.END_AT.LABEL')
                    ]}
                    disabled={form.storeIdentifier !== auth.me?.storeIdentifier}
                  />
                </Col>
              </Row>
              {showQuantity && (
                <Row>
                  <Col md={4}>
                    <AdvancedInput
                      label={translate('PAGES.PANEL.COUPON.DETAILS.FORM.INITIAL_QUANTITY.LABEL')}
                      value={form.initialQuantity}
                      type="number"
                      onChange={(val: number) => {
                        onFormChange('initialQuantity', val);
                        onFormChange('currentQuantity', val);
                      }}
                      inputProps={{ min: 0 }}
                      disabled={pageType === PAGE_TYPE.EDIT}
                    />
                  </Col>
                  {pageType === PAGE_TYPE.EDIT && (
                    <Col md={4}>
                      <AdvancedInput
                        label={translate('PAGES.PANEL.COUPON.DETAILS.FORM.CURRENT_QUANTITY.LABEL')}
                        value={form.currentQuantity}
                        type="number"
                        onChange={(val: number) => onFormChange('currentQuantity', val)}
                        inputProps={{ min: 0 }}
                        disabled={pageType === PAGE_TYPE.EDIT}
                      />
                    </Col>
                  )}
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <Link to={getRouteStackPath('COMMERCIAL', 'COUPON_REPORT')}>
                    <AdvancedButton
                      variant="text"
                      text={translate(
                        'PAGES.PANEL.COUPON.DETAILS.FORM.BACK.LABEL'
                      )}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md={2}>
                  <AdvancedButton
                    type="submit"
                    className="coupon__advanced-button"
                    text={translate(
                      'PAGES.PANEL.COUPON.DETAILS.FORM.SUBMIT.LABEL'
                    )}
                    endIcon={<SaveOutlined />}
                    disabled={form.storeIdentifier !== auth.me?.storeIdentifier}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default CouponDetails;
