import { SaveOutlined, CloudUploadOutlined, LeftOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';

import * as ProductActions from '~/actions/product';
import * as CategoryActions from '~/actions/category';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedAutoComplete from '~/components/AdvancedAutoComplete/AdvancedAutoComplete';
import AdvancedUploader from '~/components/AdvancedUploader/AdvancedUploader';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import { USER_PAGE_TYPE, PAGE_TYPE } from '~/enum/pageType';
import { getRouteStackPath } from '~/config/routes';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';
import { getCategoriesOptions } from '~/utils/selectOptions';

const formInitialValues: models.Product = {
  title: '',
  picUrl: '',
  price: 0,
  categoryId: '',
};

const currencyInitialValues: models.Currency = {
  min: 0,
  step: 0.01,
};

const ProductDetails: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);
  const [currency] = useState(currencyInitialValues);

  const { pathname } = useLocation();
  const { category, product, auth } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.Product) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (product && product.detail) {
      dispatch(CategoryActions.getCategoryAll());
      setForm(product.detail);
    } else {
      setForm(formInitialValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(CategoryActions.getCategoryAll());
      dispatch(ProductActions.cleanProductDetail());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  useEffect(() => {
    if (auth.me?.profileType !== USER_PAGE_TYPE.SHOPKEEPER) {
      window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    }
  }, [auth]);

  const onFormSubmit = () => {
    const requestForm: any = {
      title: form.title,
      price: form.price,
      picture: form.picture,
      categoryId: form.categoryId,
    };

    if (!form.title) {
      return MessageService.error('PAGES.PANEL.PRODUCT.DETAILS.FORM.ERROR.TITLE');
    }

    if (!form.price) {
      return MessageService.error('PAGES.PANEL.PRODUCT.DETAILS.FORM.ERROR.PRICE');
    }

    if (!form.picture && pageType === PAGE_TYPE.ADD) {
      return MessageService.error('PAGES.PANEL.PRODUCT.DETAILS.FORM.ERROR.PICURL');;
    }

    if (!form.categoryId) {
      return MessageService.error('PAGES.PANEL.PRODUCT.DETAILS.FORM.ERROR.CATEGORY');
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(ProductActions.updateProduct(product?.detail?.id as string, requestForm));
    } else {
      dispatch(ProductActions.addProduct(requestForm));
    } 
  };

  return (
    <div className="product">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.PRODUCT.REPORT.TITLE'),
                url: getRouteStackPath('COMMERCIAL', 'PRODUCT_REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.PRODUCT.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="product__details__form">
            <div className="product__details__form__title">
              <h3 className="product__details__form__title__text">
                {translate('PAGES.PANEL.PRODUCT.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.PRODUCT.DETAILS.FORM.SUBJECT.LABEL')}
                    value={form.title}
                    onChange={(val: string | null) => onFormChange('title', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.PRODUCT.DETAILS.FORM.PRICE.LABEL')}
                    value={form.price}
                    type="number"
                    onChange={(val: number) => onFormChange('price', val)}
                    inputProps={{ min: currency.min, step: currency.step }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  {category.all.length > 0 && (
                    <AdvancedAutoComplete
                      value={form.categoryId}
                      label={translate('PAGES.PANEL.PRODUCT.DETAILS.FORM.CATEGORY.LABEL')}
                      options={getCategoriesOptions(category.all)}
                      onChange={(val: string | null) => onFormChange('categoryId', val)}
                    />
                  )}
                </Col>  
              </Row>
              <Row>
                <Col lg={8}>
                  <label>{translate('PAGES.PANEL.PRODUCT.DETAILS.FORM.PICURL.LABEL')}</label>
                  <AdvancedUploader
                    onChange={(val: models.File) => onFormChange('picture', val.base64)}
                    value={form.picture}
                    defaultImage={form.picUrl || undefined}
                  >
                    <AdvancedButton
                      text={translate('PAGES.PANEL.PRODUCT.DETAILS.FORM.UPLOAD.LABEL')}
                      endIcon={<CloudUploadOutlined />}
                      variant="outlined"
                      color="dark"
                      disableElevation
                    />
                  </AdvancedUploader>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {pageType === PAGE_TYPE.ADD 
                    ? (
                      <Link to={getRouteStackPath('COMMERCIAL', 'PRODUCT_REPORT')}>
                        <AdvancedButton
                          variant="text"
                          text={translate(
                            'PAGES.PANEL.PRODUCT.DETAILS.FORM.BACK.LABEL'
                          )}
                          startIcon={<LeftOutlined />}
                        />
                      </Link>
                    ) : (
                      <AdvancedButton
                        variant="text"
                        text={translate(
                          'PAGES.PANEL.PRODUCT.DETAILS.FORM.BACK.LABEL'
                        )}
                        onClick={() => history.goBack()}
                        startIcon={<LeftOutlined />}
                      />
                    )}
                </Col>
                <Col md={2}>
                  <AdvancedButton
                    type="submit"
                    className="push__advanced-button"
                    text={translate(
                      'PAGES.PANEL.PRODUCT.DETAILS.FORM.SUBMIT.LABEL'
                    )}
                    endIcon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ProductDetails;
