import moment, { Moment } from 'moment-timezone';

export const buildCalendar = (month: number, year: number) => {
  const days = [];

  const startDay = moment([year, month - 1]).clone().startOf('month').startOf('week');
  const lastDay = moment([year, month - 1]).clone().endOf('month').endOf('week');
  const day = startDay.clone().subtract(1, 'day');

  while (day.isBefore(lastDay, 'day')) {
    days.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, 'day').clone()),
    );
  }

  return days;
}

export const beforeToday = (date: Moment) => {
  return date.isBefore(new Date(), 'day');
}

export const isToday = (date: Moment) => {
  return date.isSame(new Date(), 'day');
}

export const getDateStyle = (date: Moment) => {
  if (beforeToday(date)) return 'before';
  if (isToday(date)) return 'today';
  return 'after';
}

export const getDayAppointments = (
  date: Moment, 
  appointments: models.CalendarAppointments[]
): models.CalendarAppointments => {
  const appointment = appointments.find((o) => date.isSame(o.scheduleDate, 'day'));

  return appointment || { scheduleDate: date.format('YYYY-MM-DD'), total: 0 };
}

export const daysOfWeek = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];