import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import PushNotificationReport from '~/pages/Panel/PushNotification/Report/PushNotificationReport';
import PushNotificationDetails from '~/pages/Panel/PushNotification/Details/PushNotificationDetails';

const PushNotificationNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('COMMERCIAL', 'PUSH_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PUSH.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.PUSH.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <PushNotificationReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'PUSH_REGISTER')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PUSH.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.PUSH.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <PushNotificationDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'PUSH_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PUSH.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.PUSH.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <PushNotificationDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default PushNotificationNavigationStack;
