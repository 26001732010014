import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { getStackPath } from '@portal/config/routes';

import BannerNavigationStack from '~/pages/Panel/Poster/Banner/BannerNavigationStack';
import CampaignNavigationStack from '~/pages/Panel/Poster/Campaign/CampaignNavigationStack';

const PosterNavigationStack = () => {
  return (
    <Switch>
      <Route path={getStackPath('POSTER')}>
        <BannerNavigationStack />
        <CampaignNavigationStack />
      </Route>
    </Switch>
  );
};

export default PosterNavigationStack;
