import { i18nReducer } from 'react-redux-i18n';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './auth';
import loadingReducer from './loading';
import userReducer from './user';
import pushNotificationReducer from './pushNotification';
import restaurantReducer from './restaurant';
import storeReducer from './store';
import categoryReducer from './category';
import productReducer from './product';
import tableRestaurantReducer from './tableRestaurant';
import reservationReducer from './reservation';
import posterReducer from './poster';
import eventReducer from './event';
import campaignPromotionReducer from './campaignPromotion';
import serviceReducer from './service';
import couponReducer from './coupon';
import soundAlertReducer from './soundAlert';

const appReducer = combineReducers({
  auth: authReducer,
  i18n: i18nReducer,
  loading: loadingReducer,
  user: userReducer,
  pushNotification: pushNotificationReducer,
  store: storeReducer,
  category: categoryReducer,
  restaurant: restaurantReducer,
  product: productReducer,
  tableRestaurant: tableRestaurantReducer,
  reservation: reservationReducer,
  poster: posterReducer,
  event: eventReducer,
  campaignPromotion: campaignPromotionReducer,
  service: serviceReducer,
  coupon: couponReducer,
  soundAlert: soundAlertReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

const storeCreator = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f
  )
);

export default storeCreator;
