import {
  POSTER_COUNT,
  POSTER_DETAIL,
  POSTER_REPORT,
} from '~/actions/actionTypes';

const initialState: reducers.PosterReducer = {
  detail: null,
  list: [],
  listCount: 0,
  total: 0,
};

const posterReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case POSTER_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case POSTER_REPORT:
      state = {
        ...state,
        list: action.payload.rows,
        listCount: action.payload.count,
      };
      break;

    case POSTER_COUNT:
      state = {
        ...state,
        total: action.payload.count,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default posterReducer;