const products: models.Product[] = [
  {
    id: 'nike-shox',
    title: 'Nike Shox',
    picUrl: 'https://static.netshoes.com.br/produtos/tenis-nike-shox-nz-eu-masculino/14/D12-9970-014/D12-9970-014_zoom1.jpg?ts=1592994254',
    price: 699.90,
    categoryId: 'tenis',
    createdAt: '2021-05-07T20:00:00',
  },
];

export const getProductMockReport = () => {
  const response = {
    rows: products,
    count: products.length,
  };

  return response;
};

export const getProductMockDetail = (id: string) => {
  const response = products.find((o) => o.id === id);

  if (!response) { throw Error('ENTITY_NOT_FOUND'); }

  return response;
}