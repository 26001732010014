import { Upload } from 'antd';
import React, { useState } from 'react';
import { compressImages } from '@portal/utils/imageUtils';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import { getBase64 } from '@portal/utils/string';
import * as MessageService from '~/services/message';

const AdvancedUploader = ({
  disabled,
  children,
  multiple,
  onPreview,
  defaultImage,
  className,
  uploadClassName,
  type,
  onChange,
  onRemove,
  value,
  multiplePreview,
  fileList,
  icon,
  text,
}: any) => {
  const onFileChange = async ({ file }: any) => {
    if (file) {
      const validFile = 
        file.type === 'image/jpeg' || 
        file.type === 'image/png' ||
        file.type === 'application/pdf' ||
        file.type === 'audio/wav' || 
        file.type === 'audio/mp3' ||
        file.type === 'video/mp4' ||
        file.type === 'video/flv' ||
        file.type === 'video/wmv';

      if(!validFile && type !== 'image'){
        return MessageService.error('COMPONENTS.ADVANCED_FILE_UPLOAD.ERROR');
      }

      if (['image/jpeg', 'image/png'].includes(file.type)){
        file.base64 = await compressImages([file]);
      } else {
        file.base64 = await getBase64(file);
      }

      file.extension = file.name.split('.').pop();
      onChange(file);
      toggleFilePreview(file ?  file.base64 : value || null);

    } else {
      onChange(null);
    }
  };

  const toggleFilePreview = (file: any) => {
    setFilePreview(file);
  };

  const [filePreview, setFilePreview] = useState(false);

  return (
    <div className={className || "advanced-file-upload"}>
      <Upload
        className={uploadClassName || "advanced-file-upload__upload"}
        customRequest={onFileChange}
        disabled={disabled}
        multiple={multiple}
        onPreview={onPreview || (() => {})}
      >
        {(filePreview || defaultImage) && !multiplePreview && (
          <div className="advanced-file-upload__preview">
            <img
              className="advanced-file-upload__preview__img"
              src={filePreview ? value : defaultImage}
              alt="preview"
            />
          </div>
        )}
        {multiplePreview && (
          <div className="advanced-file-upload__preview-container">
            {fileList &&
              fileList.length > 0 && 
              fileList.map((o: any) => (
                <div key={o.id || o.uid} className="advanced-file-upload__preview-container__section">
                  <div className="advanced-file-upload__preview-container__section__item">
                    <AdvancedButton
                      size="medium"
                      onClick={() => onRemove(o)}
                      endIcon={icon}
                      text={text}
                    />
                    <object>
                      <embed
                        className="advanced-file-upload__preview-container__section__item__file" 
                        src={o.base64 || o.url} 
                        title={o.name}
                      /> 
                    </object>
                  </div>
                </div>
            ))}
          </div>
        )}
        {children}
      </Upload>
    </div>
  );
};

export default AdvancedUploader;
