import getInstance from './instance';

const CampaignPromotionApi = {
  getReport: async (params: advancedFilterModels.baseFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/commercial/campaign-promotion', { params });

    return data;
  },

  getAll: async (params?: advancedFilterModels.baseFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/commercial/campaign-promotion/all', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/commercial/campaign-promotion/${id}`);

    return data;
  },
};

export default CampaignPromotionApi;
