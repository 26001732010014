import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '~/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import CampaignDetails from '~/pages/Panel/Poster/Campaign/Details/CampaignDetails';
import CampaignReport from '~/pages/Panel/Poster/Campaign/Report/CampaignReport';

const CampaignNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('POSTER', 'CAMPAIGN_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.BANNER.CAMPAIGN.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.BANNER.CAMPAIGN.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <CampaignReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('POSTER', 'CAMPAIGN_REGISTER')}`}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.BANNER.CAMPAIGN.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.BANNER.CAMPAIGN.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <CampaignDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('POSTER', 'CAMPAIGN_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.BANNER.CAMPAIGN.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.BANNER.CAMPAIGN.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <CampaignDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default CampaignNavigationStack;
