import { translate } from '~/services/i18n';
import { USER_PAGE_TYPE } from '~/enum/pageType';
import { PUSH_NOTIFICATION_REDIRECT_TYPE } from '~/enum/pushNotification';
import { POSTER_CONTENT_TYPE } from '~/enum/poster';

export const getCategoriesOptions = (categories: Array<models.Category>) => {
  return categories.map((o) => {
    return {
      name: o.title,
      value: o.id,
    };
  });
};

export const getPushSegmentationOptions = (
  pushSegmentations: Array<models.PushNotificationSegmentation>
) => {
  return pushSegmentations.map((o) => {
    return {
      name: o.title,
      value: o.value,
    };
  });
};

export const getStoreOptions = (stores: Array<models.Store>) => {
  return stores.map((o) => {
    return {
      name: `${o.tradeName} (${o.identifier})`,
      value: o.identifier,
    };
  });
};

export const getRestaurantOptions = (restaurants: Array<models.Restaurant>) => {
  return restaurants.map((o) => {
    return {
      name: `${o.tradeName} (${o.identifier})`,
      value: o.identifier,
    };
  });
};

export const getEventOptions = (events: Array<models.Event>) => {
  return events.map((o) => {
    return {
      name: `${o.header} ($${o.identifier})`,
      value: o.identifier,
    };
  });
};

export const getCampaignPromotionOptions = (campaignsPromotions: Array<models.CampaignPromotion>) => {
  return campaignsPromotions.map((o) => {
    return {
      name: `${o.header} ($${o.identifier})`,
      value: o.identifier,
    };
  });
};

export const getServiceOptions = (services: Array<models.Service>) => {
  return services.map((o) => {
    return {
      name: `${o.header} ($${o.identifier})`,
      value: o.identifier,
    };
  });
};

export const getPushRedirectTypeOptions = () =>  ([
  {
    name: translate('PAGES.PANEL.PUSH.DETAILS.FORM.REDIRECT_TYPE.OPTIONS.NONE'),
    value: PUSH_NOTIFICATION_REDIRECT_TYPE.NONE,
  },
]);

export const getProfileOptions = () => ([
  {
    name: translate('PAGES.PANEL.SHOPKEEPER.DETAILS.FORM.PROFILE_TYPE.OPTIONS.DEFAULT'),
    value: USER_PAGE_TYPE.DEFAULT,
  },
  {
    name: translate('PAGES.PANEL.SHOPKEEPER.DETAILS.FORM.PROFILE_TYPE.OPTIONS.SHOPKEEPER'),
    value: USER_PAGE_TYPE.SHOPKEEPER,
  },
  {
    name: translate('PAGES.PANEL.SHOPKEEPER.DETAILS.FORM.PROFILE_TYPE.OPTIONS.RESTAURANT'),
    value: USER_PAGE_TYPE.RESTAURANT,
  }
]);

export const getPosterContentOptions = () => ([
  {
    name: translate('SHARED.ENUMERATORS.CONTENT_TYPE.NONE'),
    value: POSTER_CONTENT_TYPE.NONE,
  },
  {
    name: translate('SHARED.ENUMERATORS.CONTENT_TYPE.EVENT'),
    value: POSTER_CONTENT_TYPE.EVENT,
  },
  {
    name: translate('SHARED.ENUMERATORS.CONTENT_TYPE.CAMPAIGN_PROMOTION'),
    value: POSTER_CONTENT_TYPE.CAMPAIGN_PROMOTION,
  },
  {
    name: translate('SHARED.ENUMERATORS.CONTENT_TYPE.SERVICE'),
    value: POSTER_CONTENT_TYPE.SERVICE,
  },
  {
    name: translate('SHARED.ENUMERATORS.CONTENT_TYPE.STORE'),
    value: POSTER_CONTENT_TYPE.STORE,
  },
  {
    name: translate('SHARED.ENUMERATORS.CONTENT_TYPE.GASTRONOMY'),
    value: POSTER_CONTENT_TYPE.GASTRONOMY,
  },
  {
    name: translate('SHARED.ENUMERATORS.CONTENT_TYPE.MEAL'),
    value: POSTER_CONTENT_TYPE.MEAL,
  },
]);