import getInstance from './instance';

const CouponApi = {
  getReport: async (params: advancedFilterModels.CouponAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/coupon', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/coupon/${id}`);

    return data;
  },

  me: async () => {
    const instance = await getInstance();
    const { data } = await instance.get('/coupon/me');

    return data;
  },

  add: async (params: models.Coupon) => {
    const instance = await getInstance();
    const { data } = await instance.post('/coupon', params);

    return data;
  },

  update: async (id: string, params: models.Coupon) => {
    const instance = await getInstance();
    const { data } = await instance.put(`/coupon/${id}`, params);
    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`/coupon/${id}`);

    return data;
  },
};

export default CouponApi;
