import {
  RESERVATION_DETAIL,
  RESERVATION_REPORT,
  RESERVATION_CALENDAR,
} from '~/actions/actionTypes';

const initialState: reducers.ReservationReducer = {
  detail: null,
  list: [],
  listCount: 0,
  calendar: [],
};

const reservationReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case RESERVATION_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case RESERVATION_REPORT:
      state = {
        ...state,
        list: action.payload.rows,
        listCount: action.payload.count,
      };
      break;

    case RESERVATION_CALENDAR:
      state = {
        ...state,
        calendar: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default reservationReducer;