import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import PanelContentTopBar from '~/components/PanelContentTopBar/PanelContentTopBar';
import PanelSidebar from '~/components/PanelSidebar/PanelSidebar';
import { getRoutes, getStackPath } from '~/config/routes';
import NotFound from '~/pages/Panel/NotFound/NotFound';

import DashboardNavigationStack from '~/pages/Panel/Dashboard/DashboardNavigationStack';
import AdminNavigationStack from '~/pages/Panel/Admin/AdminNavigationStack';
import PushNotificationNavigationStack from '~/pages/Panel/PushNotification/PushNotificationNavigationStack';
import RestaurantNavigationStack from '~/pages/Panel/Restaurant/RestaurantNavigationStack';
import ShopkeeperNavigationStack from '~/pages/Panel/Shopkeeper/ShopkeeperNavigationStack';
import StoreNavigationStack from '~/pages/Panel/Store/StoreNavigationStack';
import PosterNavigationStack from '~/pages/Panel/Poster/PosterNavigationStack';
import CouponNavigationStack from '~/pages/Panel/Coupon/CouponNavigationStack';

import CategoryNavigationStack from '~/pages/Panel/Category/CategoryNavigationStack';
import ProductNavigationStack from '~/pages/Panel/Product/ProductNavigationStack';
import TableRestaurantNavigationStack from '~/pages/Panel/TableRestaurant/TableRestaurantNavigationStack';
import ReservationNavigationStack from '~/pages/Panel/Reservation/ReservationNavigationStack';

const PanelNavigationStack: React.FC = () => {
  const loggedUser = useSelector(
    (state: reducers.rootReducer) => state.auth.me
  );

  return (
    <div className="panel-navigation-stack">
      <div className="panel-navigation-stack__sidebar">
        <PanelSidebar routes={getRoutes()} />
      </div>

      <div className="panel-navigation-stack__content">
        <PanelContentTopBar user={loggedUser} />

        <Switch>
          <Route path={getStackPath('DASHBOARD')}>
            <DashboardNavigationStack />
          </Route>

          <Route path={getStackPath('USER')}>
            <AdminNavigationStack />
            <ShopkeeperNavigationStack />
          </Route>

          <Route path={getStackPath('ESTABLISHMENT')}>
            <RestaurantNavigationStack />
            <StoreNavigationStack />
          </Route>

          <Route path={getStackPath('COMMERCIAL')}>
            <CategoryNavigationStack />
            <CouponNavigationStack />
            .<ProductNavigationStack />
            <PushNotificationNavigationStack />
            <ReservationNavigationStack />
            <TableRestaurantNavigationStack />
          </Route>

          <Route path={getStackPath('POSTER')}>
            <PosterNavigationStack />
          </Route>

          <Route>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default PanelNavigationStack;
