import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import AdvancedCalendar from '~/components/AdvancedCalendar/AdvancedCalendar';
import KPICard from '~/components/KPICard/KPICard';

import { TableRestaurantActions, ReservationActions } from '~/actions';
import ReservationIcon from '~/assets/svg/kpi-cards/ic_reservation.svg';
import TableRestaurantIcon from '~/assets/svg/kpi-cards/ic_table.svg';

import { useReduxState } from '~/hooks/useReduxState';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';

const initialTableRestaurantValues: advancedFilterModels.TableRestaurantAdvancedFilter = {
  restaurantId: '',
  categoryId: '',
  chairsAmount: null,
  orderSheet: '',
  breakfastStartPeriod: '',
  breakfastEndPeriod: '',
  lunchStartPeriod: '',
  lunchEndPeriod: '',
  dinnerStartPeriod: '',
  dinnerEndPeriod: '',
  reserved: null,
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};

const initialReservationValues: advancedFilterModels.ReservationAdvancedFilter = {
  restaurantId: null,
  scheduleDate: null,
  tableRestaurantId: null,
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};

const DashboardRestaurantDetails: React.FC = () => {
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState<Moment>(moment());
  const { auth, reservation, tableRestaurant } = useReduxState();

  const getDefaultTableRestaurantFilters = (): advancedFilterModels.TableRestaurantAdvancedFilter => {
    return {
      ...initialTableRestaurantValues,
      restaurantId: auth.me?.storeIdentifier || '',
    };
  };

  const getDefaultReservationFilters = (): advancedFilterModels.ReservationAdvancedFilter => {
    return {
      ...initialReservationValues,
      restaurantId: auth.me?.storeIdentifier || '',
    };
  };

  const handleScheduleChange = (val: number) => {
    const scheduleChanged = moment(schedule).add(val, 'month');
    setSchedule(scheduleChanged);

    dispatch(ReservationActions.getReservationCalendar({
      period: scheduleChanged.format('YYYY-MM'),
    }));
  };
  
  useEffect(() => {
    dispatch(ReservationActions.getReservationReport(getDefaultReservationFilters()));
    dispatch(TableRestaurantActions.getTableRestaurantReport(getDefaultTableRestaurantFilters()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="panel-dashboard__kpi">
      <div className="panel-dashboard__kpi__indicators">
        <Row>
          <Col md={9}>
            <KPICard
              icon={TableRestaurantIcon}
              number={tableRestaurant?.listCount || 0}
              description={translate('PAGES.PANEL.DASHBOARD.DETAILS.CARD.TABLE_RESTAURANT')}
            />
          </Col>
        </Row>
        <Row>
          <Col md={9} className="panel-dashboard__kpi--inner">
            <KPICard
              icon={ReservationIcon}
              number={reservation?.listCount || 0}
              description={translate('PAGES.PANEL.DASHBOARD.DETAILS.CARD.RESERVATION')}
            />
          </Col>
        </Row>
      </div>
      <div className="panel-dashboard__kpi__calendar">
        <AdvancedCalendar
          appointmentTitle={translate('PAGES.PANEL.DASHBOARD.DETAILS.CALENDAR.APPOINTMENT.RESTAURANT')} 
          currentAppointments={reservation.calendar || []}
          currentMonth={schedule.month() + 1}
          currentYear={schedule.year()}
          onChange={(val: number) => handleScheduleChange(val)}
        />
      </div>
    </div>
  );
}

export default DashboardRestaurantDetails;