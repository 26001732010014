import { PlusCircleOutlined } from '@ant-design/icons';
import { GridCellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import * as TableRestaurantActions from '~/actions/tableRestaurant';

import AdvancedFilters from '~/components/AdvancedFilters/AdvancedFilters';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import PanelContentSearchBar from '~/components/PanelContentSearchBar/PanelContentSearchBar';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { getRouteStackPath } from '~/config/routes';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { USER_PAGE_TYPE } from '~/enum/pageType';
import { useReduxState } from '~/hooks/useReduxState';

const initialValues: advancedFilterModels.TableRestaurantAdvancedFilter = {
  restaurantId: '',
  categoryId: '',
  chairsAmount: null,
  orderSheet: '',
  breakfastStartPeriod: '',
  breakfastEndPeriod: '',
  lunchStartPeriod: '',
  lunchEndPeriod: '',
  dinnerStartPeriod: '',
  dinnerEndPeriod: '',
  reserved: null,
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};

const TableRestaurantReport: React.FC = () => {
  const dispatch = useDispatch();
  const { tableRestaurant, auth } = useReduxState();

  const getDefaultFilters = (): advancedFilterModels.TableRestaurantAdvancedFilter => {
    return {
      ...initialValues,
      restaurantId: auth.me?.storeIdentifier || '',
    };
  };

  const [advancedFilters, setAdvancedFilters] = useState(getDefaultFilters());
  const reportRows = tableRestaurant.list;
  const reportTotalRows = tableRestaurant.listCount;

  useEffect(() => {
    const filter = NavigationService.getQuery();

    onSearch({
      ...advancedFilters,
      ...filter,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth.me?.profileType !== USER_PAGE_TYPE.RESTAURANT) {
      window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    }
  }, [auth]);

  const onSearch = (filters: advancedFilterModels.TableRestaurantAdvancedFilter) => {
    dispatch(TableRestaurantActions.getTableRestaurantReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(TableRestaurantActions.removeTableRestaurant(id));

    const filter = NavigationService.getQuery();

    onSearch({
      ...advancedFilters,
      ...filter,
    });
  };

  return (
    <div className="tableRestaurant">
      <div className="tableRestaurant__advanced-filters">
        <PanelContentSearchBar
          advancedFilter={
            <AdvancedFilters
              onFilter={() => onSearch(advancedFilters)}
              onClear={() => {
                setAdvancedFilters(getDefaultFilters());
                onSearch(getDefaultFilters());
              }}
              cols={[3, 3, 3]}
              fields={[
                {
                  label: translate(
                    'PAGES.PANEL.TABLE_RESTAURANT.REPORT.ADVANCED_FILTER.ORDER_SHEET'
                  ),
                  onChange: (orderSheet: string) => {
                    setAdvancedFilters({
                      ...advancedFilters,
                      orderSheet,
                    });
                  },
                  type: 'input',
                  value: advancedFilters.orderSheet,
                },
              ]}
            />
          }
        />
      </div>

      <div className="tableRestaurant__panel-content">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.PAGE_TITLE')}
              pageDescription={translate(
                'PAGES.PANEL.TABLE_RESTAURANT.REPORT.PAGE_DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={6}>
            <Link to={getRouteStackPath('TABLE_RESTAURANT', 'REGISTER')}>
              <AdvancedButton
                className="push__advanced-button"
                text={translate('COMPONENTS.DATA_TABLE_ACTIONS.ADD.LABEL')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
      </div>

      <Row>
        <Col>
          <div className="tableRestaurant__table">
            <DataTable
              rows={reportRows}
              columns={[
                {
                  field: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.ORDER_SHEET.FIELD'),
                  flex: 1,
                  headerName: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.ORDER_SHEET.HEADER'),
                },
                {
                  field: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.CHAIRS.FIELD'),
                  flex: 1,
                  headerName: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.CHAIRS.HEADER'),
                },
                {
                  field: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.CATEGORY.FIELD'),
                  flex: 1,
                  headerName: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.CATEGORY.HEADER'),
                  valueFormatter: (params) => params.row?.category?.title,
                },
                {
                  field: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.RESERVED.FIELD'),
                  flex: 1,
                  headerName: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.RESERVED.HEADER'),
                  renderCell: (o: GridCellParams) => {
                    return (
                      <>
                        {translate(
                          o.value 
                          ? 'PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.RESERVED.OPTION.TRUE' 
                          : 'PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.RESERVED.OPTION.FALSE'
                        )}
                      </>
                    );
                  }
                },
                {
                  field: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.CREATED.FIELD'),
                  flex: 1,
                  headerName: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.CREATED.HEADER'),
                  renderCell: (o: GridCellParams) => {
                    return (
                      <>
                        {DateTime.fromISO(o.value as string).toLocaleString(
                          DateTime.DATETIME_SHORT
                        )}
                      </>
                    );
                  },
                },
                {
                  align: 'center',
                  field: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.ACTIONS.FIELD'),
                  headerAlign: 'center',
                  headerName: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TABLE.ACTIONS.HEADER'),
                  renderCell: (o: GridCellParams) => {
                    return (
                      <DataTableActions
                        row={o.row}
                        basePath={translate('PAGES.PANEL.TABLE_RESTAURANT.ROUTE.DETAILS')}
                        onRemove={(id: string) => onRemove(id)}
                        onDetail={(id: string) => dispatch(TableRestaurantActions.getTableRestaurantDetail(id))}
                      />
                    );
                  },
                },
              ]}
              rowCount={reportTotalRows}
              pageSize={advancedFilters.pageSize}
              page={advancedFilters.page}
              sort={advancedFilters.sort}
              orderBy={advancedFilters.orderBy}
              onChange={(filters) => {
                const searchFilters = {
                  ...advancedFilters,
                  ...filters,
                };
                setAdvancedFilters(searchFilters);
                onSearch(searchFilters);
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TableRestaurantReport;
