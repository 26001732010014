import getInstance from './instance';

const EventsApi = {
  getReport: async (params: advancedFilterModels.baseFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/commercial/events', { params });

    return data;
  },

  getAll: async (params?: advancedFilterModels.baseFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/commercial/events/all', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/commercial/events/${id}`);

    return data;
  },
};

export default EventsApi;
