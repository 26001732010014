import { SaveOutlined, DeleteOutlined, CloudUploadOutlined, LeftOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { PosterActions, RestaurantActions, ServiceActions, StoreActions, EventActions, CampaignPromotionActions } from '~/actions';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import AdvancedAutoComplete from '~/components/AdvancedAutoComplete/AdvancedAutoComplete';
import AdvancedUploader from '~/components/AdvancedUploader/AdvancedUploader';
import AdvancedCheckbox from '~/components/AdvancedCheckbox/AdvancedCheckbox';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import { PAGE_TYPE } from '~/enum/pageType';
import { POSTER_TYPE, POSTER_CONTENT_TYPE, FILE_LOCATION } from '~/enum/poster';
import { getRouteStackPath } from '~/config/routes';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';
import { 
  getPosterContentOptions,
  getCampaignPromotionOptions,
  getEventOptions,
  getRestaurantOptions,
  getServiceOptions,
  getStoreOptions 
} from '~/utils/selectOptions';
import { getFileType } from '~/utils/string';

const formInitialValues: models.Poster = {
  title: '',
  description: '',
  badge: '',
  type: POSTER_TYPE.BANNER,
  order: 1,
  contentType: POSTER_CONTENT_TYPE.NONE,
  content: '',
  validateInvoice: false,
  files: [],
};

const rangeInitialValues: models.Range = {
  min: 1,
  max: 1,
};

const BannerDetails: React.FC = () => {
  const dispatch = useDispatch();
  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);
  const [range, setRange] = useState(rangeInitialValues);

  const [thumbnail, setThumbnail] = useState<models.FileEntity>({});
  const [file, setFile] = useState<models.FileEntity>({});
  const [fileList, setFileList] = useState<models.FileEntity[]>([]);
  const [removedList, setRemovedList] = useState<models.FileEntity[]>([]);

  const { pathname } = useLocation();
  const { poster, event, campaignPromotion, service, store, restaurant } = useReduxState();

  const onFormChange = (key: string, val: string | boolean | number | null) => {
    setForm((prevState: models.Poster) => ({ ...prevState, [key]: val }));
  };

  const onContentTypeChange = (val: string | boolean | number | null) => {
    onFormChange('contentType', val);
    onFormChange('content', '');
    onFormChange('validateInvoice', false);
  };

  const handleSetFileList = (file: models.FileEntity) => {
    if (file.uid) {
      setFileList([...fileList, file]);
    }
  };

  const handleRemoveList = (file: any) => {
    if (file.id) { setRemovedList([...removedList, file]); }

    const index = fileList.findIndex((o) => o.name === file.name);

    if (index > -1) {
      fileList.splice(index, 1);
      setFileList([...fileList]);
    }
  };

  const renewContentOptions = useCallback((contentType: number) => {
    if (
      contentType === POSTER_CONTENT_TYPE.CAMPAIGN_PROMOTION
      && campaignPromotion.all.length === 0
    ) {
      dispatch(CampaignPromotionActions.getCampaignPromotionAll());
    }

    if (contentType === POSTER_CONTENT_TYPE.EVENT && event.all.length === 0) {
      dispatch(EventActions.getEventAll());
    }

    if (contentType === POSTER_CONTENT_TYPE.SERVICE && service.all.length === 0) {
      dispatch(ServiceActions.getServiceAll());
    }

    if (contentType === POSTER_CONTENT_TYPE.STORE && store.all.length === 0) {
      dispatch(StoreActions.getStoreAll());
    }

    if (contentType === POSTER_CONTENT_TYPE.GASTRONOMY && restaurant.gastronomy.length === 0) {
      dispatch(RestaurantActions.getRestaurantGastronomyAll());
    }

    if (contentType === POSTER_CONTENT_TYPE.MEAL && restaurant.meal.length === 0) {
      dispatch(RestaurantActions.getRestaurantMealAll());
    }
  }, [event, campaignPromotion, service, store, restaurant, dispatch]);

  useEffect(() => {
    if (poster && poster.detail) {
      renewContentOptions(poster.detail.contentType || POSTER_CONTENT_TYPE.NONE);
      setForm(poster.detail);
      setRange({ min: 1, max: poster.total });
      setThumbnail(
        (poster.detail.files && poster.detail.files[0]) || {}
      )
      setFile(
        (poster.detail.files && poster.detail.files[1]) || {}
      );
      setFileList(poster.detail.files?.slice(2) || []);

    } else {
      setForm(formInitialValues);
      setRange({ min: 1, max: poster.total + 1 });
      setThumbnail({});
      setFile({});
      setFileList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poster.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(PosterActions.cleanPosterDetail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  useEffect(() => {
    renewContentOptions(form.contentType || POSTER_CONTENT_TYPE.NONE);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.contentType]);

  const onFormSubmit = () => {
    const files = [thumbnail, file, ...fileList];

    const requestForm: any = {
      title: form.title,
      description: form.description,
      badge: form.badge,
      type: form.type,
      order: form.order,
      contentType: form.contentType,
      content: form.content,
      validateInvoice: form.validateInvoice,
      files: files.map((o, index) => {
        return {
          base64: o.base64,
          extension: o.extension,
          name: o.name,
          location: o.location,
          type: getFileType(o.extension?.toLowerCase() || ''),
          id: (form.files && form.files[index]?.id) || '',
        };
      }),
    };

    if (!form.title) {
      return MessageService.error('PAGES.PANEL.BANNER.DETAILS.FORM.ERROR.TITLE');
    }

    if (!form.description) {
      return MessageService.error('PAGES.PANEL.BANNER.DETAILS.FORM.ERROR.DESCRIPTION');
    }

    if (!file || Object.keys(file).length === 0) {
      return MessageService.error('PAGES.PANEL.BANNER.DETAILS.FORM.ERROR.MAIN_IMAGE');
    }

    if (!thumbnail || Object.keys(thumbnail).length === 0) {
      return MessageService.error('PAGES.PANEL.BANNER.DETAILS.FORM.ERROR.THUMBNAIL_IMAGE');
    }

    if (!form.badge) {
      return MessageService.error('PAGES.PANEL.BANNER.DETAILS.FORM.ERROR.BADGE');
    }

    if (form.contentType !== POSTER_CONTENT_TYPE.NONE && !form.content) {
      return MessageService.error('PAGES.PANEL.BANNER.DETAILS.FORM.ERROR.CONTENT');
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(PosterActions.updatePoster(poster?.detail?.id as string, {
        ...requestForm,
        excludedFiles: removedList.length ? removedList : undefined,
      }));
    } else {
      dispatch(PosterActions.addPoster(requestForm));
    }
  };

  return (
    <div className="banner">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.BANNER.REPORT.TITLE'),
                url: getRouteStackPath('POSTER', 'BANNER_REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.BANNER.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="banner__details__form">
            <div className="banner__details__form__title">
              <h3 className="banner__details__form__title__text">
                {translate('PAGES.PANEL.BANNER.DETAILS.FORM.HEADER')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.TITLE.LABEL')}
                    onChange={(val: string) => onFormChange('title', val)}
                    value={form.title}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.BADGE.LABEL')}
                    onChange={(val: string) => onFormChange('badge', val)}
                    value={form.badge}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.BANNER.DETAILS.FORM.ORDER.LABEL'
                    )}
                    onChange={(val: number) => onFormChange('order', val)}
                    value={form.order}
                    type='number'
                    inputProps={{ min: range.min, max: range.max }}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedAutoComplete
                    value={form.contentType}
                    label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.CONTENT_TYPE.LABEL')}
                    options={getPosterContentOptions()}
                    onChange={(val: number | null) => {
                      onContentTypeChange(val);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  {form.contentType === POSTER_CONTENT_TYPE.CAMPAIGN_PROMOTION && (
                    <AdvancedCheckbox
                      label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.VALIDATE_INVOICE.LABEL')}
                      value={form.validateInvoice}
                      onChange={(val: boolean) => onFormChange('validateInvoice', val)}
                    />
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={8}>
                  {form.contentType === POSTER_CONTENT_TYPE.EVENT 
                    && event.all.length > 0 && (
                      <AdvancedAutoComplete
                        value={form.content}
                        label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.CONTENT.LABEL')}
                        options={getEventOptions(event.all)}
                        onChange={(val: string | null) => onFormChange('content', val)}
                        disabled={pageType === PAGE_TYPE.EDIT}
                      />
                  )}
                  {form.contentType === POSTER_CONTENT_TYPE.CAMPAIGN_PROMOTION 
                    && campaignPromotion.all.length > 0 && (
                      <AdvancedAutoComplete
                        value={form.content}
                        label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.CONTENT.LABEL')}
                        options={getCampaignPromotionOptions(campaignPromotion.all)}
                        onChange={(val: string | null) => onFormChange('content', val)}
                        disabled={pageType === PAGE_TYPE.EDIT}
                      />
                  )}
                  {form.contentType === POSTER_CONTENT_TYPE.SERVICE 
                    && service.all.length > 0 && (
                      <AdvancedAutoComplete
                        value={form.content}
                        label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.CONTENT.LABEL')}
                        options={getServiceOptions(service.all)}
                        onChange={(val: string | null) => onFormChange('content', val)}
                        disabled={pageType === PAGE_TYPE.EDIT}
                      />
                  )}
                  {form.contentType === POSTER_CONTENT_TYPE.STORE 
                    && store.all.length > 0 && (
                      <AdvancedAutoComplete
                        value={form.content}
                        label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.CONTENT.LABEL')}
                        options={getStoreOptions(store.all)}
                        onChange={(val: string | null) => onFormChange('content', val)}
                        disabled={pageType === PAGE_TYPE.EDIT}
                      />
                  )}
                  {form.contentType === POSTER_CONTENT_TYPE.MEAL 
                    && restaurant.meal.length > 0 && (
                      <AdvancedAutoComplete
                        value={form.content}
                        label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.CONTENT.LABEL')}
                        options={getRestaurantOptions(restaurant.meal)}
                        onChange={(val: string | null) => onFormChange('content', val)}
                        disabled={pageType === PAGE_TYPE.EDIT}
                      />
                  )}
                  {form.contentType === POSTER_CONTENT_TYPE.GASTRONOMY 
                    && restaurant.gastronomy.length > 0 && (
                      <AdvancedAutoComplete
                        value={form.content}
                        label={translate('PAGES.PANEL.BANNER.DETAILS.FORM.CONTENT.LABEL')}
                        options={getRestaurantOptions(restaurant.gastronomy)}
                        onChange={(val: string | null) => onFormChange('content', val)}
                        disabled={pageType === PAGE_TYPE.EDIT}
                      />
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <h3>{translate('PAGES.PANEL.BANNER.DETAILS.FORM.THUMBNAIL_IMAGE.LABEL')}</h3>
                  <AdvancedUploader
                    onChange={(thumbnail: models.FileEntity) => 
                      setThumbnail({ ...thumbnail, location: FILE_LOCATION.THUMBNAIL })
                    }
                    defaultImage={thumbnail?.url || undefined}
                    value={thumbnail?.base64}
                  >
                    <AdvancedButton
                      text={translate('PAGES.PANEL.BANNER.DETAILS.FORM.UPLOAD.LABEL')}
                      endIcon={<CloudUploadOutlined />}
                      className="advanced-file-upload__upload__button"
                      variant="outlined"
                      color="dark"
                      disableElevation
                    />
                  </AdvancedUploader>
                </Col>
                <Col md={4}>
                  <h3>{translate('PAGES.PANEL.BANNER.DETAILS.FORM.MAIN_IMAGE.LABEL')}</h3>
                  <AdvancedUploader
                    onChange={(file: models.FileEntity) => 
                      setFile({ ...file, location: FILE_LOCATION.MAIN })
                    }
                    defaultImage={file?.url || undefined}
                    value={file?.base64}
                  >
                    <AdvancedButton
                      text={translate('PAGES.PANEL.BANNER.DETAILS.FORM.UPLOAD.LABEL')}
                      endIcon={<CloudUploadOutlined />}
                      className="advanced-file-upload__upload__button"
                      variant="outlined"
                      color="dark"
                      disableElevation
                    />
                  </AdvancedUploader>
                </Col>
              </Row>

              <Row>
                <Col md={8}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.BANNER.DETAILS.FORM.DESCRIPTION.LABEL'
                    )}
                    onChange={(val: string) => onFormChange('description', val)}
                    value={form.description}
                    multiline
                  />
                </Col>
              </Row>

              <Row>
                <Col md={8}>
                  <h3>
                    {translate(
                      'PAGES.PANEL.BANNER.DETAILS.FORM.ATTACHMENT.LABEL'
                    )}
                  </h3>
                  <AdvancedUploader
                    onChange={(file: models.FileEntity) => 
                      handleSetFileList({ ...file, location: FILE_LOCATION.ATTACHMENT })
                    }
                    multiplePreview
                    fileList={fileList}
                    icon={<DeleteOutlined />}
                    text={translate('SHARED.DELETE')}
                    onRemove={handleRemoveList}
                  >
                    <AdvancedButton
                      text={translate('PAGES.PANEL.BANNER.DETAILS.FORM.UPLOAD_FILE.LABEL')}
                      endIcon={<CloudUploadOutlined />}
                      className="advanced-file-upload__upload__button"
                      variant="outlined"
                      color="dark"
                      disableElevation
                    />
                  </AdvancedUploader>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Link to={getRouteStackPath('POSTER', 'BANNER_REPORT')}>
                  <AdvancedButton
                      variant="text"
                      text={translate(
                        'PAGES.PANEL.BANNER.DETAILS.FORM.BACK.LABEL'
                      )}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md={4}>
                  <AdvancedButton
                    type="submit"
                    className="banner__advanced-button"
                    text={translate(
                      'PAGES.PANEL.BANNER.DETAILS.FORM.SUBMIT.LABEL'
                    )}
                    endIcon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default BannerDetails;
