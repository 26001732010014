import { SaveOutlined, LeftOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useCallback } from 'react';
import { Divider } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

import * as TableRestaurantActions from '~/actions/tableRestaurant';
import * as CategoryActions from '~/actions/category';
import { getReservationDetail } from '~/actions/reservation';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedAutoComplete from '~/components/AdvancedAutoComplete/AdvancedAutoComplete';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import { USER_PAGE_TYPE, PAGE_TYPE } from '~/enum/pageType';
import { CATEGORY_TYPE } from '~/enum/category';
import { getRouteStackPath } from '~/config/routes';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';
import { maskCpfOrCnpj, maskHour, unmaskField } from '~/services/masks';
import { getCategoriesOptions } from '~/utils/selectOptions';

const formInitialValues: models.TableRestaurant = {
  restaurantId: '',
  categoryId: '',
  orderSheet: '',
  chairsAmount: 1,
  reserved: false,
  breakfastStartPeriod: '',
  breakfastEndPeriod: '',
  lunchStartPeriod: '',
  lunchEndPeriod: '',
  dinnerStartPeriod: '',
  dinnerEndPeriod: '',
};

const TableRestaurantDetails: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);

  const { pathname } = useLocation();
  const { tableRestaurant, auth, category } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.TableRestaurant) => ({ ...prevState, [key]: val }));
  };

  const getCategories = useCallback(() => {
    if (!category.all.length && auth.me) {
      dispatch(CategoryActions.getCategoryAll({
        storeIdentifier: auth.me?.storeIdentifier || '',
        type: CATEGORY_TYPE.TABLE_RESTAURANT,
      }));
    }
  }, [category.all, auth.me, dispatch]);

  const onReservationDetails = async (id: string) => {
    await dispatch(getReservationDetail(id));
    history.push(`${getRouteStackPath('COMMERCIAL', 'RESERVATION_DETAILS').replace(':id?', id)}`);
  };

  useEffect(() => {
    if (tableRestaurant && tableRestaurant.detail) {
      setForm({
        ...tableRestaurant.detail,
        breakfastStartPeriod: tableRestaurant.detail.breakfastStartPeriod
          ? unmaskField(tableRestaurant.detail.breakfastStartPeriod)
          : undefined,
        breakfastEndPeriod: tableRestaurant.detail.breakfastEndPeriod
          ? unmaskField(tableRestaurant.detail.breakfastEndPeriod)
          : undefined,
        lunchStartPeriod: tableRestaurant.detail.lunchStartPeriod
          ? unmaskField(tableRestaurant.detail.lunchStartPeriod)
          : undefined,
        lunchEndPeriod: tableRestaurant.detail.lunchEndPeriod
          ? unmaskField(tableRestaurant.detail.lunchEndPeriod)
          : undefined,
        dinnerStartPeriod: tableRestaurant.detail.dinnerStartPeriod 
          ? unmaskField(tableRestaurant.detail.dinnerStartPeriod)
          : undefined,
        dinnerEndPeriod: tableRestaurant.detail.dinnerEndPeriod 
          ? unmaskField(tableRestaurant.detail.dinnerEndPeriod)
          : undefined,
      });
    } else {
      setForm({
        ...formInitialValues,
        restaurantId: auth.me?.storeIdentifier || '',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRestaurant.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(TableRestaurantActions.cleanTableRestaurantDetail());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  useEffect(() => {
    if (auth.me?.profileType !== USER_PAGE_TYPE.RESTAURANT) {
      window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    }
  }, [auth]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const onFormSubmit = () => {
    const requestForm: any = {
      restaurantId: form.restaurantId,
      categoryId: form.categoryId,
      orderSheet: form.orderSheet,
      chairsAmount: form.chairsAmount,
      breakfastStartPeriod: form.breakfastStartPeriod ? form.breakfastStartPeriod : undefined,
      breakfastEndPeriod: form.breakfastEndPeriod ? form.breakfastEndPeriod : undefined,
      lunchStartPeriod: form.lunchStartPeriod ? form.lunchStartPeriod : undefined,
      lunchEndPeriod: form.lunchEndPeriod ? form.lunchEndPeriod : undefined,
      dinnerStartPeriod: form.dinnerStartPeriod ? form.dinnerStartPeriod : undefined,
      dinnerEndPeriod: form.dinnerEndPeriod ? form.dinnerEndPeriod : undefined,
    };

    if (!form.orderSheet) {
      return MessageService.error('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.ERROR.ORDER_SHEET');
    }

    if (!form.categoryId) {
      return MessageService.error('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.ERROR.CATEGORY');
    }

    if (!form.chairsAmount || form.chairsAmount < 1) {
      return MessageService.error('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.ERROR.CHAIRS_AMOUNT');
    }

    if ((!form.breakfastStartPeriod || !form.breakfastEndPeriod)
      && (!form.lunchStartPeriod || !form.lunchEndPeriod) 
      && (!form.dinnerStartPeriod || !form.dinnerEndPeriod)) {
      return MessageService.error('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.ERROR.AVAILABLE_PERIOD_MISSING');
    }

    if ((!form.breakfastStartPeriod && form.breakfastEndPeriod) 
      || (form.breakfastStartPeriod && !form.breakfastEndPeriod)) {
        return MessageService.error('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.ERROR.AVAILABLE_PERIOD_INVALID');
    }

    if ((!form.lunchStartPeriod && form.lunchEndPeriod) 
      || (form.lunchStartPeriod && !form.lunchEndPeriod)) {
        return MessageService.error('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.ERROR.AVAILABLE_PERIOD_INVALID');
    }

    if ((!form.dinnerStartPeriod && form.dinnerEndPeriod) 
      || (form.dinnerStartPeriod && !form.dinnerEndPeriod)) {
        return MessageService.error('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.ERROR.AVAILABLE_PERIOD_INVALID');
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(TableRestaurantActions.updateTableRestaurant(tableRestaurant?.detail?.id as string, requestForm));
    } else {
      dispatch(TableRestaurantActions.addTableRestaurant(requestForm));
    } 
  };

  return (
    <div className="tableRestaurant">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.TITLE'),
                url: getRouteStackPath('COMMERCIAL', 'TABLE_RESTAURANT_REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="tableRestaurant__details__form">
            <div className="tableRestaurant__details__form__title">
              <h3 className="tableRestaurant__details__form__title__text">
                {translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.ORDER_SHEET.LABEL')}
                    value={form.orderSheet}
                    onChange={(val: string) => onFormChange('orderSheet', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.CHAIRS_AMOUNT.LABEL')}
                    value={form.chairsAmount}
                    onChange={(val: number) => onFormChange('chairsAmount', val)}
                    type="number"
                    inputProps={{ min: 1 }}
                  />
                </Col>
              </Row>
              {category?.all?.length > 0 && (
                <Row>
                  <Col md={8}>
                    <AdvancedAutoComplete
                      value={form.categoryId}
                      options={getCategoriesOptions(category.all)}
                      label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.CATEGORY.LABEL')}
                      onChange={(val: string | null) => onFormChange('categoryId', val)}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.BREAKFAST.START.LABEL')}
                    value={form.breakfastStartPeriod && maskHour(form.breakfastStartPeriod as string)}
                    onChange={(val: string) => onFormChange('breakfastStartPeriod', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.BREAKFAST.END.LABEL')}
                    value={form.breakfastEndPeriod && maskHour(form.breakfastEndPeriod as string)}
                    onChange={(val: string) => onFormChange('breakfastEndPeriod', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.LUNCH.START.LABEL')}
                    value={form.lunchStartPeriod && maskHour(form.lunchStartPeriod as string)}
                    onChange={(val: string) => onFormChange('lunchStartPeriod', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.LUNCH.END.LABEL')}
                    value={form.lunchEndPeriod && maskHour(form.lunchEndPeriod as string)}
                    onChange={(val: string) => onFormChange('lunchEndPeriod', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.DINNER.START.LABEL')}
                    value={form.dinnerStartPeriod && maskHour(form.dinnerStartPeriod as string)}
                    onChange={(val: string) => onFormChange('dinnerStartPeriod', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.DINNER.END.LABEL')}
                    value={form.dinnerEndPeriod && maskHour(form.dinnerEndPeriod as string)}
                    onChange={(val: string) => onFormChange('dinnerEndPeriod', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Link to={getRouteStackPath('COMMERCIAL', 'TABLE_RESTAURANT_REPORT')}>
                    <AdvancedButton
                      variant="text"
                      text={translate(
                        'PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.BACK.LABEL'
                      )}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md={2}>
                  <AdvancedButton
                    type="submit"
                    className="tableRestaurant__advanced-button"
                    text={translate(
                      'PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.SUBMIT.LABEL'
                    )}
                    endIcon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>

      {pageType === PAGE_TYPE.EDIT 
        && form.reservations?.length 
        && form.reservations?.length > 0 
        && (
          <React.Fragment>
            <Divider />
            <div className="tableRestaurant__details__form__list">
              <h4 className="tableRestaurant__details__form__list__text">
                {translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.RESERVATIONS.REPORT')}
              </h4>
            </div>

            {form.reservations.map((o) => (
              <Row className="tableRestaurant__details__form__list__item">
                <Col md={3}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.RESERVATIONS.USER.LABEL')}
                    value={o.user?.document && maskCpfOrCnpj(o.user.document)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={2}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.RESERVATIONS.SCHEDULE_DATE.LABEL')}
                    value={
                      DateTime.fromISO(o.scheduleDate as string).toLocaleString(
                        DateTime.DATE_SHORT,
                      )
                    }
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={2}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.RESERVATIONS.START.LABEL')}
                    value={
                      DateTime.fromISO(o.start as string).toLocaleString(
                        DateTime.TIME_SIMPLE,
                      )
                    }
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={2}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.RESERVATIONS.END.LABEL')}
                    value={
                      DateTime.fromISO(o.end as string).toLocaleString(
                        DateTime.TIME_SIMPLE,
                      )
                    }
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={2} className="tableRestaurant__details__form__list__item__advanced-button">
                  <AdvancedButton
                    text={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.FORM.RESERVATIONS.DETAILS')}
                    onClick={() => onReservationDetails(o.id as string)}
                  />
                </Col>
              </Row>
            ))}
          </React.Fragment>
        )}
    </div>
  );
};
export default TableRestaurantDetails;
