import { Dispatch } from 'redux';
import * as MessageService from '~/services/message';
import RestaurantRequests from '~/repositories/restaurant';

import { 
  RESTAURANT_REPORT, 
  RESTAURANT_DETAIL, 
  RESTAURANT_ALL, 
  RESTAURANT_GASTRONOMY_ALL,
  RESTAURANT_MEAL_ALL 
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanRestaurantDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: RESTAURANT_DETAIL,
  });
};

export const getRestaurantGastronomyAll = () => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Restaurant> = await RestaurantRequests.getGastronomyAll();

    dispatch({
      payload: payload.rows,
      type: RESTAURANT_GASTRONOMY_ALL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const getRestaurantMealAll = () => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Restaurant> = await RestaurantRequests.getMealAll();

    dispatch({
      payload: payload.rows,
      type: RESTAURANT_MEAL_ALL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const getRestaurantAll = () => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Restaurant> = await RestaurantRequests.getAll();

    dispatch({
      payload: payload.rows,
      type: RESTAURANT_ALL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const getRestaurantReport = (
  searchParams: advancedFilterModels.RestaurantAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Restaurant> = await RestaurantRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page > 0 ? searchParams.page - 1 : searchParams.page,
        limit: searchParams.pageSize,
        isDESC: searchParams.sort === 'desc' ? 'true' : 'false',
      }
    );

    dispatch({
      payload: {
        ...payload,
        rows: payload.rows.map((o) => {
          return {
            ...o,
            id: o.identifier ? o.identifier : o.id,
          }
        }),
      },
      type: RESTAURANT_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getRestaurantDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Restaurant = await RestaurantRequests.getDetail(id);

    dispatch({
      payload,
      type: RESTAURANT_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};