export const POSTER_TYPE = {
  BANNER: 1,
  CAMPAIGN: 2,
};

export const POSTER_CONTENT_TYPE = {
  NONE: 1,
  EVENT: 2,
  CAMPAIGN_PROMOTION: 3,
  SERVICE: 4,
  STORE: 5,
  MEAL: 6,
  GASTRONOMY: 7,
};

export const FILE_TYPE = {
  AUDIO: 1,
  VIDEO: 2,
  IMAGE: 3,
  PDF: 4,
  OTHER: 5,
};

export const FILE_LOCATION = {
  THUMBNAIL: 1,
  MAIN: 2,
  ATTACHMENT: 3,
};