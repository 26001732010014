
import { FILE_TYPE } from '~/enum/poster';

export const imageExtensions = ['png', 'jpg', 'jpeg'];
export const audioExtensions = ['mp3', 'wav'];
export const videoExtensions = ['flv', 'mp4', 'wmv'];

export async function getBase64(file: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
}

export function getFileType(extension: string) {
  let fileType = FILE_TYPE.OTHER;

  if (imageExtensions.includes(extension)) {
    fileType = FILE_TYPE.IMAGE;
  }

  if (audioExtensions.includes(extension)) {
    fileType = FILE_TYPE.AUDIO;
  }

  if (videoExtensions.includes(extension)) {
    fileType = FILE_TYPE.VIDEO;
  }

  if (extension === 'pdf') {
    fileType = FILE_TYPE.PDF;
  }

  return fileType;
}