import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import StoreReport from '~/pages/Panel/Store/Report/StoreReport';
import StoreDetails from '~/pages/Panel/Store/Details/StoreDetails';

const StoreNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ESTABLISHMENT', 'STORE_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.STORE.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.STORE.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <StoreReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('ESTABLISHMENT', 'STORE_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.STORE.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.STORE.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <StoreDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default StoreNavigationStack;
