import React from 'react';
import { Divider } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';

import * as ProductActions from '~/actions/product';
import { translate } from '~/services/i18n';
import { getRouteStackPath } from '~/config/routes';

interface IProps {
  products: models.Product[],
};

const CategoryProducts: React.FC<IProps> = ({ products }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onProductDetails = async (id: string) => {
    await dispatch(ProductActions.getProductDetail(id));
    history.push(`${getRouteStackPath('PRODUCT', 'DETAILS').replace(':id?', id)}`);
  };
 
  return (
    <>
      <Divider />
        <div className="category__details__form__list">
          <h4 className="category__details__form__list__text">
            {translate('PAGES.PANEL.CATEGORY.DETAILS.FORM.PRODUCTS.REPORT')}
          </h4>
        </div>

        {products.map((o: any) => (
          <Row className="category__details__form__list__item">
            <Col md={2}>
              <div className="category__details__form__list__item__pic">
                <img 
                  src={o.picUrl} 
                  alt={o.title}
                  className="category__details__form__list__item__pic__img" 
                />
              </div>
             </Col>
             <Col md={4}>
               <AdvancedInput
                  label={translate('PAGES.PANEL.CATEGORY.DETAILS.FORM.PRODUCTS.TITLE.LABEL')}
                  value={o.title}
                  disabled={true}
                />
              </Col>
              <Col md={2}>
                <AdvancedInput
                  label={translate('PAGES.PANEL.CATEGORY.DETAILS.FORM.PRODUCTS.PRICE.LABEL')}
                  value={o.price}
                  disabled={true}
                />
              </Col>
              <Col md={2} className="category__details__form__list__item__advanced-button">
                <AdvancedButton
                  text={translate('PAGES.PANEL.CATEGORY.DETAILS.FORM.PRODUCTS.DETAILS')}
                  onClick={() => onProductDetails(o.id as string)}
                />
              </Col>
            </Row>
        ))}
    </>    
  );
};

export default CategoryProducts;
