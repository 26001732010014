import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import CouponDetails from '~/pages/Panel/Coupon/Details/CouponDetails';
import CouponReport from '~/pages/Panel/Coupon/Report/CouponReport';

const CouponNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('COMMERCIAL', 'COUPON_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.COUPON.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.COUPON.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <CouponReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'COUPON_REGISTER')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.COUPON.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.COUPON.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <CouponDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'COUPON_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.COUPON.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.COUPON.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <CouponDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default CouponNavigationStack;
