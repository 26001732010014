import { Dispatch } from 'redux';
import PushNotificationRequests from '~/repositories/pushNotification';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { PUSH_NOTIFICATION_DETAIL, PUSH_NOTIFICATION_REPORT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanPushNotificationDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: PUSH_NOTIFICATION_DETAIL,
  });
};

export const getPushNotificationReport = (
  searchParams: advancedFilterModels.PushNotificationAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.PushNotification> = await PushNotificationRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page > 0 ? searchParams.page - 1 : searchParams.page,
        limit: searchParams.pageSize,
        isDESC: searchParams.sort === 'desc' ? 'true' : 'false',
      }
    );
    dispatch({
      payload,
      type: PUSH_NOTIFICATION_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getPushNotificationDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PushNotification = await PushNotificationRequests.getDetail(id);

    dispatch({
      payload,
      type: PUSH_NOTIFICATION_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const addPushNotification = (
  params: models.PushNotification, callback?: (data: any) => void
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    let response = null;

    if (params.file) {
      const formData = new FormData();

      formData.append('file', params.file);
      formData.append('title', params.title);
      formData.append('body', params.body);

      if (params.sendDate) {
        formData.append('sendDate', params.sendDate?.toString());
      }

      response = await PushNotificationRequests.addUpload(formData);
    } else {
      response = await PushNotificationRequests.add(params);
    }

    if (callback && typeof callback === 'function') {
      callback(response);
    }

    MessageService.success('PAGES.PANEL.PUSH.DETAILS.SUCCESS_ADD_MESSAGE');

    centralNavigationService.back();
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removePushNotification = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await PushNotificationRequests.remove(id);
    MessageService.success('PAGES.PANEL.PUSH.DETAILS.SUCCESS_REMOVE_MESSAGE');

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};
