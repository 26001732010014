export const PUSH_NOTIFICATION_SEGMENTATION: any = {
  TEXT: 1,
  FILE: 2,
};

export const PUSH_NOTIFICATION_STATUS = {
  PENDING: 1,
  SEND: 2,
  SCHEDULED: 3,
  CANCELED: 4,
}

export const PushNotificationStatusMap = new Map([
  [PUSH_NOTIFICATION_STATUS.PENDING, 'PENDING'],
  [PUSH_NOTIFICATION_STATUS.SEND, 'SEND'],
  [PUSH_NOTIFICATION_STATUS.SCHEDULED, 'SCHEDULED'],
  [PUSH_NOTIFICATION_STATUS.CANCELED, 'CANCELED'],
])

export const PUSH_NOTIFICATION_REDIRECT_TYPE = {
  NONE: 1,
}