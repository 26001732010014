import { Dispatch } from 'redux';
import TableRestaurantRequests from '~/repositories/tableRestaurant';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { TABLE_RESTAURANT_REPORT, TABLE_RESTAURANT_DETAIL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanTableRestaurantDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: TABLE_RESTAURANT_DETAIL,
  });
};

export const getTableRestaurantReport = (
  searchParams: advancedFilterModels.TableRestaurantAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.TableRestaurant> = await TableRestaurantRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page > 0 ? searchParams.page - 1 : searchParams.page,
        limit: searchParams.pageSize,
        isDESC: searchParams.sort === 'desc' ? 'true' : 'false',
      }
    );

    dispatch({
      payload,
      type: TABLE_RESTAURANT_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getTableRestaurantDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.TableRestaurant = await TableRestaurantRequests.getDetail(id);

    dispatch({
      payload,
      type: TABLE_RESTAURANT_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateTableRestaurant = (id: string, params: models.TableRestaurant) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await TableRestaurantRequests.update(id, params);

    MessageService.success('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.SUCCESS_EDIT_MESSAGE');
    centralNavigationService.back();

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const addTableRestaurant = (params: models.TableRestaurant) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await TableRestaurantRequests.add(params);

    MessageService.success('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.SUCCESS_ADD_MESSAGE');
    centralNavigationService.back();

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeTableRestaurant = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await TableRestaurantRequests.remove(id);
    MessageService.success('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.SUCCESS_REMOVE_MESSAGE');

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};