import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import ShopkeeperReport from '~/pages/Panel/Shopkeeper/Report/ShopkeeperReport';
import ShopkeeperDetails from '~/pages/Panel/Shopkeeper/Details/ShopkeeperDetails';

const ShopkeeperNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('USER', 'SHOPKEEPER_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.SHOPKEEPER.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.SHOPKEEPER.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <ShopkeeperReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('USER', 'SHOPKEEPER_REGISTER')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.SHOPKEEPER.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.SHOPKEEPER.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <ShopkeeperDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('USER', 'SHOPKEEPER_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.SHOPKEEPER.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.SHOPKEEPER.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <ShopkeeperDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default ShopkeeperNavigationStack;
