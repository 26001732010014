import { Dispatch } from 'redux';
import CouponRequests from '~/repositories/coupon';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { COUPON_REPORT, COUPON_DETAIL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanCouponDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: COUPON_DETAIL,
  });
};

export const getCouponReport = (
  searchParams: advancedFilterModels.CouponAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Coupon> = await CouponRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page > 0 ? searchParams.page - 1 : searchParams.page,
        limit: searchParams.pageSize,
        isDESC: searchParams.sort === 'desc' ? 'true' : 'false',
      }
    );

    dispatch({
      payload,
      type: COUPON_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getCouponDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Coupon = await CouponRequests.getDetail(id);

    dispatch({
      payload,
      type: COUPON_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateCoupon = (id: string, params: models.Coupon) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await CouponRequests.update(id, params);

    MessageService.success('PAGES.PANEL.COUPON.DETAILS.SUCCESS_EDIT_MESSAGE');
    centralNavigationService.back();

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const addCoupon = (params: models.Coupon) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await CouponRequests.add(params);

    MessageService.success('PAGES.PANEL.COUPON.DETAILS.SUCCESS_ADD_MESSAGE');
    centralNavigationService.back();

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeCoupon = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await CouponRequests.remove(id);
    MessageService.success('PAGES.PANEL.COUPON.DETAILS.SUCCESS_REMOVE_MESSAGE');

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};