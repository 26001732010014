import { USER_PAGE_TYPE } from '~/enum/pageType';

/* tslint:disable:object-literal-sort-keys */
const routes: models.route[] = [
  {
    id: 'DASHBOARD',
    name: 'Dashboard',
    route: '/dashboard',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'DETAILS',
        name: 'Detalhes',
        route: '/relatorio/detalhes',
      },
    ],
  },
  {
    id: 'USER',
    name: 'Usuários',
    route: '/usuarios',
    icon: '/assets/svg/panel-sidebar/ic_users.svg',
    iconAlt: 'Usuários',
    accessType: USER_PAGE_TYPE.ADMIN,
    items: [
      {
        id: 'ADMINISTRATOR_REPORT',
        name: 'Administradores',
        route: '/administradores/lista',
      },
      {
        id: 'ADMINISTRATOR_REGISTER',
        name: 'Administradores',
        route: '/administradores/registro',
        sidebarHidden: true,
      },
      {
        id: 'ADMINISTRATOR_DETAILS',
        name: 'Administradores',
        route: '/administradores/detalhes/:id?',
        sidebarHidden: true,
      },
      {
        id: 'SHOPKEEPER_REPORT',
        name: 'Lojistas',
        route: '/lojistas/lista',
      },
      {
        id: 'SHOPKEEPER_REGISTER',
        name: 'Lojistas',
        route: '/lojistas/registro',
        sidebarHidden: true,
      },
      {
        id: 'SHOPKEEPER_DETAILS',
        name: 'Lojistas',
        route: '/lojistas/detalhes/:id?',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'ESTABLISHMENT',
    name: 'Estabelecimentos',
    route: '/estabelecimentos',
    icon: '/assets/svg/panel-sidebar/ic_establishment.svg',
    iconAlt: 'Estabelecimentos',
    accessType: USER_PAGE_TYPE.ADMIN,
    items: [
      {
        id: 'STORE_REPORT',
        name: 'Lojas',
        route: '/lojas/lista',
      },
      {
        id: 'STORE_DETAILS',
        name: 'Lojas',
        route: '/lojas/detalhes/:id?',
        sidebarHidden: true,
      },
      {
        id: 'RESTAURANT_REPORT',
        name: 'Gastronomia',
        route: '/restaurantes/lista',
      },
      {
        id: 'RESTAURANT_DETAILS',
        name: 'Gastronomia',
        route: '/restaurantes/detalhes/:id?',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'COMMERCIAL',
    name: 'Comercial',
    route: '/comercial',
    icon: '/assets/svg/panel-sidebar/ic_commercial.svg',
    iconAlt: 'Comercial',
    items: [
      {
        id: 'PUSH_REPORT',
        name: 'Notificações',
        route: '/notificacoes/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'PUSH_REGISTER',
        name: 'Notificações',
        route: '/notificacoes/registro',
        accessType: USER_PAGE_TYPE.ADMIN,
        sidebarHidden: true,
      },
      {
        id: 'PUSH_DETAILS',
        name: 'Notificações',
        route: '/notificacoes/detalhes/:id?',
        accessType: USER_PAGE_TYPE.ADMIN,
        sidebarHidden: true,
      },
      {
        id: 'COUPON_REPORT',
        name: 'Cupons',
        route: '/cupons/lista',
      },
      {
        id: 'COUPON_REGISTER',
        name: 'Cupons',
        route: '/cupons/registro',
        sidebarHidden: true,
      },
      {
        id: 'COUPON_DETAILS',
        name: 'Cupons',
        route: '/cupons/detalhes/:id?',
        sidebarHidden: true,
      },
      {
        id: 'CATEGORY_REPORT',
        name: 'Categorias',
        route: '/categorias/lista',
        accessType: USER_PAGE_TYPE.SHOPKEEPER,
        accessGranted: [USER_PAGE_TYPE.SHOPKEEPER, USER_PAGE_TYPE.RESTAURANT],
      },
      {
        id: 'CATEGORY_REGISTER',
        name: 'Categorias',
        route: '/categorias/registro',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.SHOPKEEPER,
        accessGranted: [USER_PAGE_TYPE.SHOPKEEPER, USER_PAGE_TYPE.RESTAURANT],
      },
      {
        id: 'CATEGORY_DETAILS',
        name: 'Categorias',
        route: '/categorias/detalhes/:id?',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.SHOPKEEPER,
        accessGranted: [USER_PAGE_TYPE.SHOPKEEPER, USER_PAGE_TYPE.RESTAURANT],
      },
      {
        id: 'PRODUCT_REPORT',
        name: 'Produtos',
        route: '/produtos/lista',
        accessType: USER_PAGE_TYPE.SHOPKEEPER,
      },
      {
        id: 'PRODUCT_REGISTER',
        name: 'Produtos',
        route: '/produtos/registro',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.SHOPKEEPER,
      },
      {
        id: 'PRODUCT_DETAILS',
        name: 'Produtos',
        route: '/produtos/detalhes/:id?',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.SHOPKEEPER,
      },
      {
        id: 'TABLE_RESTAURANT_REPORT',
        name: 'Mesas',
        route: '/mesas-restaurante/lista',
        accessType: USER_PAGE_TYPE.RESTAURANT,
      },
      {
        id: 'TABLE_RESTAURANT_REGISTER',
        name: 'Mesas',
        route: '/mesas-restaurante/registro',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.RESTAURANT,
      },
      {
        id: 'TABLE_RESTAURANT_DETAILS',
        name: 'Mesas',
        route: '/mesas-restaurante/detalhes/:id?',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.RESTAURANT,
      },
      {
        id: 'RESERVATION_REPORT',
        name: 'Reservas',
        route: '/reservas/lista',
        accessType: USER_PAGE_TYPE.RESTAURANT,
      },
      {
        id: 'RESERVATION_DETAILS',
        name: 'Reservas',
        route: '/reservas/detalhes/:id?',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.RESTAURANT,
      },
    ],
  },
  {
    id: 'POSTER',
    name: 'Customização',
    route: '/poster',
    icon: '/assets/svg/panel-sidebar/ic_poster.svg',
    iconAlt: 'Customização',
    accessType: USER_PAGE_TYPE.ADMIN,
    items: [
      {
        id: 'BANNER_REPORT',
        name: 'Banners',
        route: '/banner/lista',
      },
      {
        id: 'BANNER_REGISTER',
        name: 'Registro de banner',
        route: '/banner/registro',
        sidebarHidden: true,
      },
      {
        id: 'BANNER_DETAILS',
        name: 'Detalhes de banner',
        route: '/banner/detalhes/:id?',
        sidebarHidden: true,
      },
      {
        id: 'CAMPAIGN_REPORT',
        name: 'Campanhas/Promoções',
        route: '/campanha-promocao/lista',
      },
      {
        id: 'CAMPAIGN_REGISTER',
        name: 'Registro de campanha/promoção',
        route: '/campanha-promocao/registro',
        sidebarHidden: true,
      },
      {
        id: 'CAMPAIGN_DETAILS',
        name: 'Detalhes de campanha/promoção',
        route: '/campanha-promocao/detalhes/:id?',
        sidebarHidden: true,
      },
      
    ],
  },
];

export const getRoutes = (): models.route[] => routes;

export const getRoutestack = (route: string): models.route =>
  routes.find((o) => o.route === route) as models.route;

export const getStackPath = (stackId: string): string => {
  return `${routes.find((o) => o.id === stackId)?.route}`;
};

export const routeExist = (route: string): boolean => {
  const routeTop = routes.find((o) => route.startsWith(o.route));

  if (!routeTop) {
    return false;
  }
  if (routeTop.route.length === route.length) {
    return false;
  }

  return (
    (routeTop.items.find((o) => `${routeTop.route}${o.route}` === route) &&
      true) ||
    false
  );
};

export const getRouteStackPath = (stackId: string, routeId: string): string => {
  const route = routes.find((o) => o.id === stackId);

  return `${route?.route}${route?.items.find((o) => o.id === routeId)?.route}`;
};
