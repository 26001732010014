import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import RestaurantReport from '~/pages/Panel/Restaurant/Report/RestaurantReport';
import RestaurantDetails from '~/pages/Panel/Restaurant/Details/RestaurantDetails';

const RestaurantNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ESTABLISHMENT', 'RESTAURANT_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.RESTAURANT.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.RESTAURANT.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <RestaurantReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('ESTABLISHMENT', 'RESTAURANT_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.RESTAURANT.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.RESTAURANT.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <RestaurantDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default RestaurantNavigationStack;
