import {
  CAMPAIGN_PROMOTION_ALL,
  CAMPAIGN_PROMOTION_DETAIL,
  CAMPAIGN_PROMOTION_REPORT,
} from '~/actions/actionTypes';

const initialState: reducers.CampaignPromotionReducer = {
  detail: null,
  list: [],
  listCount: 0,
  all: [],
};

const campaignPromotionReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case CAMPAIGN_PROMOTION_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case CAMPAIGN_PROMOTION_REPORT:
      state = {
        ...state,
        list: action.payload.rows,
        listCount: action.payload.count,
      };
      break;

    case CAMPAIGN_PROMOTION_ALL:
      state = {
        ...state,
        all: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default campaignPromotionReducer;