import { Dispatch } from 'redux';
import PosterRequests from '~/repositories/poster';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { POSTER_DETAIL, POSTER_REPORT, POSTER_COUNT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanPosterDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: POSTER_DETAIL,
  });
};

export const getPosterCount = (
  searchParams: advancedFilterModels.PosterAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Count = await PosterRequests.getCount(searchParams);
    dispatch({
      payload,
      type: POSTER_COUNT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERROR.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const getPosterReport = (
  searchParams: advancedFilterModels.PosterAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Poster> = await PosterRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page > 0 ? searchParams.page - 1 : searchParams.page,
        limit: searchParams.pageSize,
        isDESC: searchParams.sort === 'desc' ? 'true' : 'false',
      }
    );
    dispatch({
      payload,
      type: POSTER_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERROR.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getPosterDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Poster = await PosterRequests.getDetail(id);

    dispatch({
      payload,
      type: POSTER_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERROR.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updatePoster = (
  id: string, 
  params: models.Poster, 
  type?: string
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await PosterRequests.update(id, params);

    if (type) {
      MessageService.success(`PAGES.PANEL.BANNER.${type}.DETAILS.SUCCESS_EDIT_MESSAGE`);
    } else {
      MessageService.success(`PAGES.PANEL.BANNER.DETAILS.SUCCESS_EDIT_MESSAGE`);
    }

    centralNavigationService.back();

  } catch (err) {
    MessageService.error(`APPLICATION.ERROR.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}

export const addPoster = (params: models.Poster, type?: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await PosterRequests.add(params);

    if (type) {
      MessageService.success(`PAGES.PANEL.BANNER.${type}.DETAILS.SUCCESS_ADD_MESSAGE`);
    } else {
      MessageService.success(`PAGES.PANEL.BANNER.DETAILS.SUCCESS_ADD_MESSAGE`);
    }

    centralNavigationService.back();
  } catch (err) {
    MessageService.error(`APPLICATION.ERROR.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removePoster = (id: string, type?: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await PosterRequests.remove(id);

    if (type) {
      MessageService.success(`PAGES.PANEL.BANNER.${type}.DETAILS.SUCCESS_REMOVE_MESSAGE`);
    } else {
      MessageService.success(`PAGES.PANEL.BANNER.DETAILS.SUCCESS_REMOVE_MESSAGE`);
    }

  } catch (err) {
    MessageService.error(`APPLICATION.ERROR.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};
