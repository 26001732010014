import { Dispatch } from 'redux';
import ReservationRequests from '~/repositories/reservation';
import * as MessageService from '~/services/message';

import { RESERVATION_REPORT, RESERVATION_DETAIL, RESERVATION_CALENDAR } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanReservationDetail = () => async (dispatch: Dispatch) => {
  dispatch({
    payload: null,
    type: RESERVATION_DETAIL,
  });
};

export const getReservationReport = (
  searchParams: advancedFilterModels.ReservationAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Reservation> = await ReservationRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page > 0 ? searchParams.page - 1 : searchParams.page,
        limit: searchParams.pageSize,
        isDESC: searchParams.sort === 'desc' ? 'true' : 'false',
      }
    );

    dispatch({
      payload,
      type: RESERVATION_REPORT,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getReservationDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Reservation = await ReservationRequests.getDetail(id);

    dispatch({
      payload,
      type: RESERVATION_DETAIL,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeReservation = (id: string) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    await ReservationRequests.remove(id);
    MessageService.success('PAGES.PANEL.RESERVATION.DETAILS.SUCCESS_REMOVE_MESSAGE');

  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getReservationCalendar = (
  searchParams: advancedFilterModels.ReservationCalendarAdvancedFilter
) => async (dispatch: Dispatch) => {
  dispatch(increaseLoading());
  try {
    const payload: models.CalendarAppointments[] = await ReservationRequests.get('/reservation/calendar', searchParams);

    dispatch({
      payload,
      type: RESERVATION_CALENDAR,
    });
  } catch (err) {
    MessageService.error(`APPLICATION.ERRORS.${err.message}`);
  } finally {
    dispatch(decreaseLoading());
  }
}
