import getInstance from './instance';

const SoundAlertApi = {
  getReport: async (params: advancedFilterModels.SoundAlertAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/sound-alert', { params });

    return data;
  },

  getAll: async (params?: advancedFilterModels.SoundAlertAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/sound-alert/all', { params });
    return data;
  },

  getCount: async (params?: advancedFilterModels.SoundAlertAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/sound-alert/count', { params });
    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/sound-alert/${id}`);

    return data;
  },

  add: async (params: models.SoundAlert) => {
    const instance = await getInstance();
    const { data } = await instance.post('/sound-alert', params);

    return data;
  },

  update: async (id: string, params: models.SoundAlert) => {
    const instance = await getInstance();
    const { data } = await instance.put(`/sound-alert/${id}`, params);
    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`/sound-alert/${id}`);

    return data;
  },
};

export default SoundAlertApi;
