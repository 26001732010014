import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment-timezone';
import { translate } from '~/services/i18n';
import { buildCalendar, getDateStyle, daysOfWeek, getDayAppointments } from '~/utils/date';

interface IAdvancedCalendarProps {
  appointmentTitle: string;
  currentAppointments: models.CalendarAppointments[];
  currentMonth: number;
  currentYear: number;
  onChange: (val: number) => void;
};

const AdvancedCalendar = ({
  appointmentTitle, 
  currentAppointments, 
  currentMonth, 
  currentYear, 
  onChange,
}: IAdvancedCalendarProps) => {
  const [calendar, setCalendar] = useState<Moment[][]>([]);

  useEffect(() => {
    setCalendar(buildCalendar(currentMonth, currentYear));
  }, [currentMonth, currentYear]);

  return (
    <div className="advanced-calendar">
      <div className="advanced-calendar__header">
        <span className="advanced-calendar__header__previous" onClick={() => onChange(-1)}>
          <LeftOutlined />
        </span>
        <div className="advanced-calendar__header__current">
          <div className="advanced-calendar__header__current__month">
            {translate(
              `SHARED.MONTHS.${moment(currentMonth, 'MM').format('MMMM').toUpperCase()}`
            )}
          </div>
          <div className="advanced-calendar__header__current__year">
            {currentYear}
          </div>
        </div>
        <span className="advanced-calendar__header__next" onClick={() => onChange(1)}>
          <RightOutlined />
        </span>
      </div>
      <div className="advanced-calendar__days-of-week">
        {daysOfWeek.map(dayOfWeek => 
          <div>
            {translate(`SHARED.DAYS_WEEK.${dayOfWeek}`)}
          </div>
        )}
      </div>
      {calendar.map(week => (
        <div className="advanced-calendar__weeks">
          {week.map(day => (
            <div className="adanced-calendar__weeks__days">
              <Popover
                className={`
                  advanced-calendar__weeks__days__${getDateStyle(day)}${
                    getDayAppointments(day, currentAppointments).total > 0
                      ? '__appointments'
                      : ''
                  }
                `}
                overlayClassName={`
                  advanced-calendar__weeks__days__popover${
                    getDayAppointments(day, currentAppointments).total > 0
                      ? ''
                      : '__hidden'
                  }
                `}
                content={
                  <>
                    {`${getDayAppointments(day, currentAppointments).total} ${appointmentTitle}`}
                  </>
                }
              >
                {day.format('DD').toString()}
              </Popover>
            </div>          
          ))}
        </div>
      ))}
    </div>
  );
};

export default AdvancedCalendar;