import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import ProductReport from '~/pages/Panel/Product/Report/ProductReport';
import ProductDetails from '~/pages/Panel/Product/Details/ProductDetails';

const ProductNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('COMMERCIAL', 'PRODUCT_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PRODUCT.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.PRODUCT.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <ProductReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'PRODUCT_REGISTER')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PRODUCT.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.PRODUCT.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <ProductDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'PRODUCT_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PRODUCT.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.PRODUCT.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <ProductDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default ProductNavigationStack;
