import { SaveOutlined, FileExcelOutlined, LeftOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Modal } from 'antd';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedAutoComplete from '~/components/AdvancedAutoComplete/AdvancedAutoComplete';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedCheckbox from '~/components/AdvancedCheckbox/AdvancedCheckbox';
import AdvancedPopOver from '~/components/AdvancedPopOver/AdvancedPopOver';
import AdvancedFileInput from '~/components/AdvancedFileInput/AdvancedFileInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import * as PushNotificationActions from '~/actions/pushNotification';
import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import { getRouteStackPath } from '~/config/routes';

import { PAGE_TYPE, USER_PAGE_TYPE } from '~/enum/pageType';
import { PUSH_NOTIFICATION_SEGMENTATION, PUSH_NOTIFICATION_REDIRECT_TYPE } from '~/enum/pushNotification';
import { getPageType } from '~/utils/page';

import { useReduxState } from '~/hooks/useReduxState';
import { maskCpfOrCnpj, maskDateTime } from '~/services/masks';
import { getPushSegmentationOptions, getPushRedirectTypeOptions } from '~/utils/selectOptions';

const formInitialValues: models.PushNotification = {
  title: '',
  body: '',
  sendDate: '',
  isSchedule: false,
  isSegmented: false,
  segmentationType: PUSH_NOTIFICATION_SEGMENTATION.TEXT,
  segmentationList: '',
  segmentationFile: null,
  redirectType: PUSH_NOTIFICATION_REDIRECT_TYPE.NONE,
};

const PushNotificationDetails: React.FC = (props) => {
  const dispatch = useDispatch();
  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);

  const { pathname } = useLocation();
  const { pushNotification, auth } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.PushNotification) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (pushNotification && pushNotification.detail) {
      setForm({
        ...pushNotification.detail,
        sendDate: DateTime.fromISO(pushNotification.detail.sendDate as string).toLocaleString(
          DateTime.DATETIME_SHORT_WITH_SECONDS
        )
      });
    } else {
      setForm(formInitialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushNotification.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(PushNotificationActions.cleanPushNotificationDetail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  useEffect(() => {
    if (auth.me?.profileType !== USER_PAGE_TYPE.ADMIN) {
      window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    }
  }, [auth]);

  const onFormSubmit = () => {
    const requestForm = {
      title: form.title,
      body: form.body,
      sendDate: form.sendDate !== ''
        ? DateTime.fromFormat(form.sendDate as string, translate('SHARED.DATETIME_FORMAT')).toJSDate()
        : null,
      userDocumentList: form.segmentationList !== ''
        ? form.segmentationList?.split('\n')
        : null,
      file: form.segmentationFile,
      redirectType: form.redirectType,
    };

    if (!form.title) {
      return MessageService.error('PAGES.PANEL.PUSH.DETAILS.FORM.ERROR.TITLE');
    }

    if (form.isSchedule && !form.sendDate) {
      return MessageService.error('PAGES.PANEL.PUSH.DETAILS.FORM.ERROR.SENDDATE');
    }

    if (!form.body) {
      return MessageService.error('PAGES.PANEL.PUSH.DETAILS.FORM.ERROR.BODY');
    }

    if (form.isSegmented) {
      if (form.segmentationType === PUSH_NOTIFICATION_SEGMENTATION.TEXT && !form.segmentationList) {
        return MessageService.error('PAGES.PANEL.PUSH.DETAILS.FORM.ERROR.SEGMENTATIONLIST');
      }

      if (form.segmentationType === PUSH_NOTIFICATION_SEGMENTATION.FILE && !form.segmentationFile) {
        return MessageService.error('PAGES.PANEL.PUSH.DETAILS.FORM.ERROR.SEGMENTATIONFILE');
      }
    }

    dispatch(PushNotificationActions.addPushNotification(requestForm, (data) => {
      if (form.isSegmented && data?.failure?.length) {
        Modal.error({
          title: translate('APPLICATION.ERRORS.FAILURE_PUSH_LIST'),
          content: (
            <div>
              {data?.failure.map((o: string) => <p>{maskCpfOrCnpj(o)}</p>)}
            </div>
          ),
        });
      }
    }));
  };

  return (
    <div className="push">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.PUSH.REPORT.TITLE'),
                url: getRouteStackPath('COMMERCIAL', 'PUSH_REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.PUSH.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="push__details__form">
            <div className="push__details__form__title">
              <h3 className="push__details__form__title__text">
                {translate('PAGES.PANEL.PUSH.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.SUBJECT.LABEL'
                    )}
                    value={form.title}
                    onChange={(val: string | null) => onFormChange('title', val)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={2}>
                  <AdvancedCheckbox
                    label={translate('PAGES.PANEL.PUSH.DETAILS.FORM.IS_SEGMENTED.LABEL')}
                    value={form.isSegmented}
                    onChange={(val: boolean) => {
                      onFormChange('isSegmented', val);

                      if (!val) {
                        onFormChange('segmentationType', PUSH_NOTIFICATION_SEGMENTATION.TEXT);
                        onFormChange('segmentationList', null);
                        onFormChange('segmentationFile', null);
                      }
                    }}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={2}>
                  <AdvancedCheckbox
                    label={translate('PAGES.PANEL.PUSH.DETAILS.FORM.IS_SCHEDULE.LABEL')}
                    value={form.isSchedule}
                    onChange={(val: boolean) => {
                      onFormChange('isSchedule', val);
                      if (!val) { onFormChange('sendDate', ''); }
                    }}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    onChange={(value: string) =>
                      onFormChange('sendDate', maskDateTime(value as string))
                    }
                    value={form.sendDate}
                    label={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.SENDDATE.LABEL'
                    )}
                    placeholder={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.SENDDATE.PLACEHOLDER'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT || !form.isSchedule}
                  />
                </Col>
              </Row>

              {form.isSegmented && (
                <React.Fragment>
                  <Row>
                    <Col md={4}>
                      <AdvancedAutoComplete
                        value={form.segmentationType}
                        label={translate('PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.TYPE.LABEL')}
                        options={getPushSegmentationOptions([
                          {
                            title: translate('PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.TEXT.TITLE'),
                            value: PUSH_NOTIFICATION_SEGMENTATION.TEXT,
                          },
                          {
                            title: translate('PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.FILE.TITLE'),
                            value: PUSH_NOTIFICATION_SEGMENTATION.FILE,
                          }
                        ])}
                        onChange={(val: number | null) => {
                          onFormChange('segmentationType', val);

                          if (val === PUSH_NOTIFICATION_SEGMENTATION.FILE) {
                            onFormChange('segmentationList', null);
                          } else if (val === PUSH_NOTIFICATION_SEGMENTATION.TEXT) {
                            onFormChange('segmentationFile', null);
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    {form.segmentationType === PUSH_NOTIFICATION_SEGMENTATION.TEXT && (
                      <Col md={6}>
                        <AdvancedPopOver
                          label={translate('PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.LABEL')}
                          text={translate('PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.TEXT.POPOVER')}
                        />
                        <AdvancedInput
                          label={translate(
                            'PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.TEXT.LABEL'
                          )}
                          value={form.segmentationList}
                          onChange={(val: string | null) =>
                            onFormChange('segmentationList', val)
                          }
                          multiline
                        />
                      </Col>
                    )}

                    {form.segmentationType === PUSH_NOTIFICATION_SEGMENTATION.FILE && (
                      <Col md={4}>
                        <AdvancedPopOver
                          label={translate('PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.LABEL')}
                          text={translate('PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.FILE.POPOVER')}
                        />
                        <AdvancedFileInput
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          identifier="push__details__form__segmentation-file"
                          icon={<FileExcelOutlined />}
                          text={translate('PAGES.PANEL.PUSH.DETAILS.FORM.SEGMENTATION.DOCUMENT.FILE.LABEL')}
                          color="#FFF"
                          onChange={(val: Array<Blob> | null) =>
                            onFormChange('segmentationFile', val ? val[0] : null
                          )}
                          filename={(form.segmentationFile as File)?.name}
                        />
                      </Col>
                    )}
                  </Row>
                </React.Fragment>
              )}

              <Row>
                <Col md={12}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.BODY.LABEL'
                    )}
                    value={form.body}
                    onChange={(val: string | null) =>
                      onFormChange('body', val)
                    }
                    multiline
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AdvancedAutoComplete
                    value={form.redirectType}
                    label={translate('PAGES.PANEL.PUSH.DETAILS.FORM.REDIRECT_TYPE.LABEL')}
                    options={getPushRedirectTypeOptions()}
                    onChange={(val: number | null) => {
                      onFormChange('redirectType', val);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={8}>
                  <Link to={getRouteStackPath('COMMERCIAL', 'PUSH_REPORT')}>
                    <AdvancedButton
                      variant="text"
                      text={translate(
                        'PAGES.PANEL.PUSH.DETAILS.FORM.BACK.LABEL'
                      )}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md={4}>
                  <AdvancedButton
                    type="submit"
                    className="push__advanced-button"
                    text={translate(
                      'PAGES.PANEL.PUSH.DETAILS.FORM.SUBMIT.LABEL'
                    )}
                    endIcon={<SaveOutlined />}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default PushNotificationDetails;
