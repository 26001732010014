import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import TableRestaurantReport from '~/pages/Panel/TableRestaurant/Report/TableRestaurantReport';
import TableRestaurantDetails from '~/pages/Panel/TableRestaurant/Details/TableRestaurantDetails';

const TableRestaurantNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('COMMERCIAL', 'TABLE_RESTAURANT_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.TABLE_RESTAURANT.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.TABLE_RESTAURANT.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <TableRestaurantReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'TABLE_RESTAURANT_REGISTER')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.TITLE_ADD')}
          pageDescription={translate(
            'PAGES.PANEL.TABLE_RESTAURANT.DETAILS.DESCRIPTION_ADD'
          )}
        >
          <TableRestaurantDetails />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('COMMERCIAL', 'TABLE_RESTAURANT_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.TABLE_RESTAURANT.DETAILS.TITLE_EDIT')}
          pageDescription={translate(
            'PAGES.PANEL.TABLE_RESTAURANT.DETAILS.DESCRIPTION_EDIT'
          )}
        >
          <TableRestaurantDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default TableRestaurantNavigationStack;
