import getInstance from './instance';

const StoreApi = {
  getReport: async (params: advancedFilterModels.StoreAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/store', { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`/store/${id}`);

    return data;
  },

  getAll: async (params?: advancedFilterModels.StoreAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get('/store/all', { params });

    return data;
  }
};

export default StoreApi;
