import { LeftOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import * as RestaurantActions from '~/actions/restaurant';
import { maskPhone, unmaskField } from '@portal/services/masks';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedImage from '~/components/AdvancedImage/AdvancedImage';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import { USER_PAGE_TYPE, PAGE_TYPE } from '~/enum/pageType';
import { getRouteStackPath } from '~/config/routes';
import { getPageType } from '~/utils/page';
import { useReduxState } from '~/hooks/useReduxState';

const formInitialValues: models.Restaurant = {
  identifier: '',
  tradeName: '',
  phone: '',
  email: '',
  floor: 1,
  segment: '',
  site: '',
  whatsappNumber: '',
  pictures: [],
  mainLogo: '',
};

const RestaurantDetails: React.FC = (props) => {
  const dispatch = useDispatch();
  const [pageType] = useState(getPageType());
  const [form, setForm] = useState(formInitialValues);

  const { pathname } = useLocation();
  const { restaurant, auth } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.Restaurant) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (restaurant && restaurant.detail) {
      setForm({
        ...restaurant.detail,
        phone: restaurant.detail.phone && unmaskField(restaurant.detail.phone),
        whatsappNumber: restaurant.detail.whatsappNumber && unmaskField(restaurant.detail.whatsappNumber),
      });
    } else {
      setForm(formInitialValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.ADD) {
      dispatch(RestaurantActions.cleanRestaurantDetail());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, pageType]);

  useEffect(() => {
    if (auth.me?.profileType !== USER_PAGE_TYPE.ADMIN) {
      window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    }
  }, [auth]);

  const onFormSubmit = () => {
    // TODO
  };

  return (
    <div className="restaurant">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                active: true,
                title: translate('PAGES.PANEL.RESTAURANT.REPORT.TITLE'),
                url: getRouteStackPath('ESTABLISHMENT', 'RESTAURANT_REPORT'),
              },
              {
                active: false,
                title: translate('PAGES.PANEL.RESTAURANT.REPORT.PAGE_TITLE_DETAILS'),
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="restaurant__details__form">
            <div className="restaurant__details__form__title">
              <h3 className="restaurant__details__form__title__text">
                {translate('PAGES.PANEL.RESTAURANT.DETAILS.FORM.TITLE')}
              </h3>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <Row>
                <Col md={8}>
                  <AdvancedImage 
                    src={form.mainLogo || ''}
                    alt={form.tradeName}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESTAURANT.DETAILS.FORM.TRADENAME.LABEL')}
                    value={form.tradeName}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string | null) => onFormChange('name', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESTAURANT.DETAILS.FORM.SITE.LABEL')}
                    value={form.site}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string | null) => onFormChange('site', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESTAURANT.DETAILS.FORM.FLOOR.LABEL')}
                    value={form.floor}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: number | null) => onFormChange('floor', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESTAURANT.DETAILS.FORM.SEGMENT.LABEL')}
                    value={form.segment}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string | null) => onFormChange('segment', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESTAURANT.DETAILS.FORM.PHONE.LABEL')}
                    value={form.phone && maskPhone(form.phone as string)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string | null) =>
                      onFormChange('phone', val)
                    }
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESTAURANT.DETAILS.FORM.WHATSAPP.LABEL')}
                    value={form.whatsappNumber && maskPhone(form.whatsappNumber)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string | null) =>
                      onFormChange('whatsappNumber', val)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.RESTAURANT.DETAILS.FORM.EMAIL.LABEL')}
                    value={form.email}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string | null) =>
                      onFormChange('email', val)
                    }
                  />
                </Col>
              </Row>
  
              <Row>
                <Col md={8}>
                  <Link to={getRouteStackPath('ESTABLISHMENT', 'RESTAURANT_REPORT')}>
                    <AdvancedButton
                      variant="text"
                      text={translate(
                        'PAGES.PANEL.RESTAURANT.DETAILS.FORM.BACK.LABEL'
                      )}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
              </Row>
            </AdvancedForm>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default RestaurantDetails;
